import React, { useEffect, useState } from "react";
import SubCategoriesProduct from "../component/subCategoriesProduct";
import ProductCategories from "../component/ProductCategories";
import CategoriesHeader from "../component/CategoriesHeader";
import { URL, ImgURL } from "../url/api";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import end_point from "../endpoints/apiendpoints";

const SubCategories = () => {
  const { id, itemName } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPageUrl = window.location.href;
  const [data, setData] = useState([]);
  const [first_catg_products, setFirst_catg_products] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [subCat, setSubCat] = useState("");
  const [selectSubCategoryId, setSelectSubCategoryId] = useState("");
  useEffect(() => {
    getCategories();
    checkLogin();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    getSubCategoriesByProduct(id);
    setSelectSubCategoryId(id);
  }, [id]);

  useEffect(() => {
    // Adjust the container height dynamically based on the viewport height
    const updateContainerHeight = () => {
      const windowHeight = window.innerHeight;
      const containerHeight = windowHeight - 75; // Adjust as needed
      const productCategoriesContainer = document.getElementById(
        "product-categories-container"
      );

      if (productCategoriesContainer) {
        productCategoriesContainer.style.height = `${containerHeight}px`;
      }
    };

    // Call the function initially and on window resize
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);
  useEffect(() => {
    // Adjust the container height dynamically based on the viewport height
    const updateContainerHeight = () => {
      const windowHeight = window.innerHeight;
      const containerHeight = windowHeight - 75; // Adjust as needed
      const productCategoriesContainer = document.getElementById(
        "product-subCategories-container"
      );

      if (productCategoriesContainer) {
        productCategoriesContainer.style.height = `${containerHeight}px`;
      }
    };

    // Call the function initially and on window resize
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
      } else {
        setIsLoggedIn(true);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const getCategories = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CATEGORY}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setData(newData?.data?.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getSubCategoriesByProduct = (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${URL}${end_point.CATEGORY_WISE_PRODUCT}/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setFirst_catg_products(newData[0]?.products?.data);
      })
      .catch((error) => console.log("error", error));
  };

  //handle response category id
  const handleSelectSubcategory = (subcategoryId) => {
    getSubCategoriesByProduct(subcategoryId.id);
    const categoryname = subcategoryId?.name
      .replace(/[\s()]+/g, "-")
      .replace(/-+$/, "");
    setSubCat(subcategoryId);
    const subcategoryUrl = subcategoryId
      ? `/category/${subcategoryId.id}/${categoryname}`
      : `/category/${id}/${itemName}`;
    navigate(subcategoryUrl);
  };

  const itemListElements = first_catg_products?.map((product, index) => ({
    "@type": "ListItem",
    position: index + 1,
    item: {
      "@type": "Product",
      name: product.p_name,
      url: `http://localhost:3000/product/${product.id}/${encodeURIComponent(
        product.p_name
      )}`,
      image: `${ImgURL}${product?.p_image[0]}`,
      description: product.p_desc || "",
    },
  }));

  const schemaData = {
    "@context": "http://schema.org",
    "@type": "ItemList",
    name: subCat?.name || "",
    url: currentPageUrl || "",
    itemListElement: itemListElements,
  };
  return (
    <div className="Container-fluid" style={{ marginTop: 75 }}>
      {/* <Helmet>
        <title>{subCat?.name}</title>
        <meta name="author" content="Zaya Dry fruits" />
        <meta name="copyright" content="Zaya Dry fruits" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={subCat.name} />
        <meta
          property="og:description"
          content="Online Shop- Zaya Dryfruits, we make it easier for you to buy these tasty and nutritious dried fruits online. Best Quality of : Badam, Cashew whole, Raisins Indian, Prunes, Dry Cranberry, Dried Kiwi, Pulses, Masale and many more. "
        />
        <meta property="og:url" content="https://admin.zayadryfruits.com/" />
        <meta property="og:site_name" content="Zaya Dry Fruits" />
        <meta
          property="og:image"
          content="http://test.zayadryfruits.com/admin/public/images/sliders/1692253370.jpeg"
        />
      </Helmet>*/}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

      <div className="container-fluid mt-5 pt-5">
        <div className="row pt-2">
          <div className="col-md-2 SubCategoriesProduct p-1">
            <div
              id="product-subCategories-container"
              className="product-subCategories-container"
              style={{ overflow: "auto" }}
            >
              <SubCategoriesProduct
                data={data}
                selectSubCategoryId={selectSubCategoryId}
                onSelectSubcategory={handleSelectSubcategory}
              />
            </div>
          </div>
          <div className="col-md-10 ProductCategories">
            <div
              id="product-categories-container"
              className="product-categories-container"
              style={{ overflow: "auto" }}
            >
              <ProductCategories
                first_catg_products={first_catg_products}
                loading={loading}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategories;
