import React from "react";
import { ImgURL } from "../url/api";
import { Helmet } from "react-helmet";
import product from "../asset/printlozy/product.png";

const subCategoriesProduct = ({
  data,
  onSelectSubcategory,
  selectSubCategoryId,
}) => {
  const handleImageError = (event) => {
    event.target.src = product; // Use the logo variable from your imports
  };
  const currentPageUrl = window.location.href;
  return (
    <div className="card width: 15rem" style={{ width: "100%" }}>
      {data?.map((item) => {
        let name = item.name;
        const product = name;
        return (
          <ul key={item.id} className="list-group list-group-flush">
            {/* <Helmet>
              <title>{item?.name}</title>
              <meta name="Description" content={item?.description} />
              <meta name="KeyWords" content="DryFruits, Nuts, Pulses" />
              <meta property="og:title" content={item?.name} />
              <meta property="og:description" content={item?.description} />
              <meta property="og:url" content={currentPageUrl} />
              <meta property="og:site_name" content="Zaya Dryfruits" />
              <meta property="og:image" content={`${ImgURL}${item.img}`} />

              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-8EXFWPNHC8"
              ></script>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-180675332-2"
              ></script>
              <script>
                {`
                          window.dataLayer = window.dataLayer || [];
                          function gtag() {
                            dataLayer.push(arguments);
                          }
                          gtag('js', new Date());
                          gtag('config', 'G-8EXFWPNHC8');
                        `}
              </script>
              <script>
                {`{ window.dataLayer = window.dataLayer || [];
                          function gtag() {
                              dataLayer.push(arguments);
                          }
                          gtag('js', new Date());

                          gtag('config', 'UA-180675332-2');}`}
              </script>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-353927359"
              ></script>
              <script>
                {`{  window.dataLayer = window.dataLayer || [];
                          function gtag() {
                              dataLayer.push(arguments);
                          }
                          gtag('js', new Date());

                          gtag('config', 'AW-353927359');}`}
              </script>
            </Helmet>*/}
            <li
              className={"list-group-item textColor align-items-center"}
              role="button"
              onClick={() => {
                onSelectSubcategory(item);
              }}
              style={{
                backgroundColor:
                  item.id == selectSubCategoryId ? "#538cee" : "#ffffff",
              }}
            >
              <div className="col-md-3">
                <img
                  src={item.image == null ? product : `${ImgURL}${item.image}`}
                  alt={item.name}
                  onError={handleImageError}
                  loading="lazy"
                  style={{ height: 35, width: 35 }}
                />
              </div>
              <div className="col-md-9">
                <p
                  className="texthover m-1"
                  style={{
                    fontSize: 12,
                    fontWeight: "600",
                    color:
                      item.id == selectSubCategoryId ? " #ffffff" : "#1d4e48",
                  }}
                >
                  {item.name}
                </p>
              </div>
            </li>
          </ul>
        );
      })}
    </div>
  );
};

export default subCategoriesProduct;
