import React, { useState, useEffect } from "react";
import { URL, ImgURL, rupees_icon } from "../url/api";
import Cookies from "js-cookie";
import ActivityIndicator from "./ActivityIndicator";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import product from "../asset/printlozy/product.png";
import end_point from "../endpoints/apiendpoints";
const FeatureProducts = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [NewArrivalData, setNewArrivalData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataVariant, setDataVariant] = useState("");
  const [Token, setToken] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [startPage, setStartPage] = useState(1);
  const visiblePages = 4;
  const [Show, setShow] = useState(false);
  useEffect(() => {
    new_arrival(currentPage);
    checkLogin();
    // Cookies.remove('user_session');
  }, [currentPage]);

  // check user login or not
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      } else {
        setIsLoggedIn(true);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // get  Latest Product use this api
  const new_arrival = (page) => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`${URL}${end_point.NEW_ARRIVAL}?page=${page}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setLoading(false);
        setNewArrivalData(newData.data.data);
        setTotalPages(newData.data.last_page);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  // Add item in the cart
  const updateCart = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(id));
    formdata.append("qty", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}${end_point.Add_CART}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        showToast(newData.msg);
        if (newData.error === false) {
          getCart();
        }
      })
      .catch((error) => console.log("error", error));
  };
  //  only cart Update
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        // {
        newData.data.cart.map((item) => {
          dispatch(refreshCart(item));
        });
        // }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleImageError = (event) => {
    event.target.src = product; // Use the logo variable from your imports
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);

    // Update startPage if we reach the end of the current visible range
    if (page >= startPage + visiblePages) {
      setStartPage(page - visiblePages + 1);
    } else if (page < startPage) {
      setStartPage(Math.max(1, page - visiblePages + 1));
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };
  const renderPagination = () => {
    const pages = [];
    pages.push(
      <li
        className={`page-item ${currentPage === 1 && "disabled"}`}
        key="first"
      >
        <button className="page-link" onClick={() => handlePageChange(1)}>
          &laquo;
        </button>
      </li>
    );
    pages.push(
      <li className={`page-item ${currentPage === 1 && "disabled"}`} key="prev">
        <button className="page-link" onClick={handlePrevious}>
          &lsaquo;
        </button>
      </li>
    );
    if (startPage > 1) {
      pages.push(
        <li className="page-item disabled" key="ellipsis-start">
          <span className="page-link">...</span>
        </li>
      );
    }
    for (
      let i = startPage;
      i <= Math.min(startPage + visiblePages - 1, totalPages);
      i++
    ) {
      if (i > totalPages) break;
      pages.push(
        <li
          className={`page-item ${i === currentPage ? "active" : ""}`}
          key={i}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    if (currentPage + visiblePages < totalPages) {
      pages.push(
        <li className="page-item disabled" key="ellipsis">
          <span className="page-link">...</span>
        </li>
      );
    }
    pages.push(
      <li
        className={`page-item ${currentPage === totalPages && "disabled"}`}
        key="next"
      >
        <button className="page-link" onClick={handleNext}>
          &rsaquo;
        </button>
      </li>
    );
    pages.push(
      <li
        className={`page-item ${currentPage === totalPages && "disabled"}`}
        key="last"
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(totalPages)}
        >
          &raquo;
        </button>
      </li>
    );

    return pages;
  };
  const displayedNewArrival = Show
    ? NewArrivalData
    : NewArrivalData.slice(0, 6);
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container newarrival">
      <div className="col-12" style={{ textAlign: "center", margin: 10 }}>
        <h3 className="textColor">New Arrivals</h3>
      </div>

      <div className="row">
        {displayedNewArrival.map((item, index) => {
          return (
            <div key={index} className="col-6 col-md-4 col-lg-2 my-2">
              <Link
                to={`/product/${item.product_id}`}
                className="card newarrivalproduct"
              >
                <div className="newarrivalimage">
                  <img
                    src={`${ImgURL}product_image/${item.p_image[0]}`}
                    alt={`${item.p_name}`}
                    onError={handleImageError}
                    className="w-100"
                  />
                </div>
                <div className="newarrivaltext">
                  <p>{item.p_name}</p>
                  <div className="new_arrival_add_text">
                    <h6>
                      <span
                        className="text-muted"
                        style={{
                          textDecoration: "line-through",
                          marginRight: "5px",
                        }}
                      >
                        {rupees_icon}
                        {item.p_mrp}
                      </span>
                      <span className="text-success fw-bold">
                        {rupees_icon}
                        {item.p_price}
                      </span>
                    </h6>
                    <div role="button" className="icon d-none">
                      <i className="fas fa-info-circle"></i>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div className="row">
        {!Show ? (
          <button
            className="btn btn-outline-success"
            onClick={() => setShow(true)}
          >
            View All
          </button>
        ) : (
          <button
            className="btn btn-outline-success"
            onClick={() => setShow(false)}
          >
            Show Less
          </button>
        )}
      </div>
      {/* <nav
        aria-label="Page navigation"
        className="d-flex justify-content-end my-4"
      >
        <ul className="pagination">{renderPagination()}</ul>
      </nav> */}
    </div>
  );
};

export default FeatureProducts;
