import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { URL, ImgURL } from "../url/api";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import ActivityIndicator from "../component/ActivityIndicator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import { Helmet } from "react-helmet";
import defaultimage from "../asset/printlozy/product.png";
import { Swiper, SwiperSlide } from "swiper/react";
import end_point from "../endpoints/apiendpoints";

const SingelProducts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [Token, setToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idVariants, setIdVariants] = useState("");
  const [selectedVariant, setselectedVariant] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [data, setData] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [allAddress, setallAddress] = useState(null);
  const [addressId, setAddressID] = useState("");
  const [swiper, setSwiper] = useState(null);
  const cartid = "";
  const currentPageUrl = window.location.href;
  useEffect(() => {
    checkLogin();
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
        getCustomerAddresses(token);
      } else {
        setIsLoggedIn(true);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const handleImageClick = (path) => {
    setSelectedImage(path);
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      fetch(`${URL}${end_point.GET_PRODUCT_DETAIL}/${id}`, {
        method: "GET",
        redirect: "follow",
      })
        .then((res) => res.text())
        .then((response) => {
          const result = JSON.parse(response);
          setData(result.data);
          setSelectedImage(result?.data?.p_image[0]);
          setIdVariants(result.data.variants[0].id);
          setselectedVariant(result.data.variants[0]);
          setSelectedColor(result.data.variants[0].p_color[0]);
          setSelectedSize(result.data.variants[0].p_size[0]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const increaseQuantity = () => {
    setQuantity((prevQty) => prevQty + 1);
  };

  // Function to decrease quantity
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQty) => prevQty - 1);
    }
  };
  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleSizeSelect = (sizeKey) => {
    setSelectedSize(sizeKey);
  };
  const handleClick = () => {
    const modal = new window.bootstrap.Modal(
      document.getElementById("customizeProductModal")
    );
    modal.show();
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle description change
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleReset = () => {
    setImage(null);
    setDescription("");
  };
  const addCart = (cartItems) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(idVariants));
    formdata.append("product_id", JSON.stringify(cartItems.id));
    formdata.append("id", cartid);
    formdata.append("qty", quantity);
    formdata.append("color", selectedColor || "");
    formdata.append("size", selectedSize || "");
    formdata.append("image", image);
    formdata.append("description", description);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}${end_point.ADD_CART}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.success === true) {
          getCart();
          fetchData();
          showToast(newData.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };
  //  only cart Update
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CART}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        newData.cart.map((item) => {
          return dispatch(refreshCart(item));
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleImageError = (event) => {
    event.target.src = defaultimage; // Use the logo variable from your imports
  };
  const getCustomerAddresses = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setallAddress(userData.data);
        setAddressID(userData.data[0].id);
      })
      .catch((error) => console.log("error", error));
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div
      // className="container"
      style={{
        flex: 1,
        marginTop: 100,
        marginRight: 10,
        marginLeft: 10,
      }}
      className="row d-flex"
    >
      {/* <Helmet>
        <title>{data?.name}</title>
        <meta name="Description" content={data?.meta_desc} />
        <meta name="KeyWords" content={data?.meta_keyword} />
        <meta property="og:title" content={data?.name} />
        <meta property="og:description" content={data?.meta_desc} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dry Fruits" />
        <meta property="og:image" content={`${ImgURL}${data.img}`} />
        <meta name="author" content="Zaya Dry fruits" />
        <meta name="copyright" content="Zaya Dry fruits" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="og:type" content="product" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-8EXFWPNHC8"
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-180675332-2"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'G-8EXFWPNHC8');
            `}
        </script>
        <script>
          {`{ window.dataLayer = window.dataLayer || [];
              function gtag() {
                  dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'UA-180675332-2');}`}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-353927359"
        ></script>
        <script>
          {`{  window.dataLayer = window.dataLayer || [];
              function gtag() {
                  dataLayer.push(arguments);
              }
              gtag('js', new Date());

              gtag('config', 'AW-353927359');}`}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Product",
              "description": "${data?.description || ""}",
              "name": "${data?.name || ""}",
              "image": "${ImgURL}${data.img || ""}",
              "url": "${currentPageUrl || ""}",
              "sku": "${data?.sku_code || ""}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.2",
                "reviewCount": "276"
              },
              "offers": {
                "@type": "Offer",
                "url": "${currentPageUrl || ""}",
                "availability": "http://schema.org/InStock",
                "price": "${
                  data?.variants && data.variants.length > 0
                    ? data.variants[0].sp
                    : ""
                }",
                "priceCurrency": "INR"
              }
            }
          `}
        </script>
      </Helmet>*/}
      <div
        className="col-md-6 d-flex"
        style={{ marginTop: 15, marginBottom: 20 }}
      >
        {data?.p_image?.length > 0 ? (
          <div className="row singaleContainer">
            <div className="col-md-4 multiImage">
              {data?.p_image?.map((image) => (
                <img
                  key={image.id}
                  src={`${ImgURL}product_image/${image}`}
                  alt={image.alt_text}
                  loading="lazy"
                  onError={handleImageError}
                  onMouseEnter={() => handleImageClick(image)}
                  className="selectedimage_singleproduct"
                  style={{
                    // cursor: "pointer",
                    // margin: "5px",
                    border:
                      selectedImage?.id === image?.id
                        ? "2px solid #538cee"
                        : "1px solid #ccc",
                    // borderRadius: "10px",
                    // height: 80,
                    // width: "70%",
                  }}
                />
              ))}
            </div>
            <div className="col-md-8" style={{ flex: 1 }}>
              <img
                key={selectedImage}
                className="singleproductimage image-hover"
                alt={selectedImage?.alt_text}
                loading="lazy"
                onError={handleImageError}
                src={`${ImgURL}product_image/${selectedImage}`}
                style={{
                  resizeMode: "cover",
                }}
              />
            </div>
          </div>
        ) : (
          <div className="col-md-6" style={{ flex: 1 }}>
            <img
              key={selectedImage?.id}
              className="singleproductimage image-hover"
              alt={data.name}
              loading="lazy"
              onError={handleImageError}
              // src={`${ImgURL}${data?.img}`}
              src={handleImageError}
              style={{
                resizeMode: "contain",
              }}
            />
          </div>
        )}
      </div>
      <div
        className="col-md-6"
        style={{
          marginTop: 35,
          textAlign: "justify",
        }}
      >
        {data.name !== null && data.name !== "" ? (
          <>
            <h6 className="textColor">Name : </h6>
            <h1
              style={{
                fontSize: 18,
                marginLeft: 8,
                fontWeight: "600",
              }}
            >
              {data.p_name}
            </h1>
            <h6
              style={{
                marginLeft: 8,
              }}
            >
              {data.p_sub_title}
            </h6>
          </>
        ) : null}
        <div className="product_varient">
          {data?.variants?.map((item, index) => {
            let calculate = (item.p_price / item.p_mrp) * 100;
            let Percentage = 100 - calculate;
            return (
              <div
                role="button"
                key={index}
                className={
                  item.id === idVariants
                    ? "item itemColor image-hover card"
                    : "item image-hover card"
                }
                onClick={() => {
                  setIdVariants(item.id);
                  setselectedVariant(item);
                  setSelectedColor(null);
                  setSelectedSize(null);
                }}
              >
                {item.p_price < item.p_mrp ? (
                  <div className="varient_percentage">
                    <p className="fw-bold pTag">{Percentage.toFixed(2)}% Off</p>
                  </div>
                ) : null}
                <div
                  className="horizontalContainer"
                  style={{
                    marginTop: 8,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {parseFloat(item.p_mrp) > parseFloat(item.p_price) ? (
                    <>
                      <p
                        className="pTag"
                        style={{ fontSize: 10, marginRight: 2 }}
                      >
                        <del>₹{parseFloat(item.p_mrp).toFixed(2)}</del>
                      </p>
                      <p
                        className="pTag"
                        style={{
                          fontSize: 12,
                          marginLeft: 2,
                          fontWeight: "bold",
                        }}
                      >
                        ₹{parseFloat(item.p_price).toFixed(2)}
                      </p>
                    </>
                  ) : (
                    <p
                      className="pTag"
                      style={{
                        fontSize: 12,
                        marginLeft: 2,
                        fontWeight: "bold",
                      }}
                    >
                      ₹{parseFloat(item.p_price).toFixed(2)}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <small>All above prices are inclusive of all taxes</small>
        {/* display quantity siv*/}
        <div className="quantitydiv">
          <button className="qtybtn" onClick={decreaseQuantity}>
            -
          </button>
          <span className="quantity">{quantity}</span>
          <button className="qtybtn" onClick={increaseQuantity}>
            +
          </button>
        </div>
        {/* display colors */}
        <div style={{ marginTop: "20px" }}>
          {selectedVariant?.p_color?.length > 0 && (
            <>
              <h6>Select Color:</h6>
              <div className="color-options">
                {selectedVariant?.p_color?.map((item, index) => (
                  <div
                    role="button"
                    key={index}
                    className={
                      selectedColor === item ? "selected_Color" : "color-swatch"
                    }
                    style={{ backgroundColor: item }}
                    title={`Color: ${item}`}
                    onClick={() => handleColorSelect(item)}
                  ></div>
                ))}
              </div>
            </>
          )}
        </div>
        {/* display sizes */}
        <div className="sizediv">
          {selectedVariant?.p_size?.length > 0 && (
            <>
              <h6>Select Sizes:</h6>
              <div className="row">
                {selectedVariant?.p_size.map((sizeKey) => (
                  <div className="col-md-3" role="button">
                    <div
                      className={
                        selectedSize === sizeKey ? "selected_size" : "card"
                      }
                      onClick={() => {
                        handleSizeSelect(sizeKey);
                      }}
                      key={sizeKey}
                    >
                      {selectedVariant.size[sizeKey]}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="customize">
          <div className="customize-product-button" onClick={handleClick}>
            <div className="content">
              <h6>Customize Product</h6>
              <p>Upload Photos and fill other details</p>
            </div>
            <i className="fa-solid fa-chevron-right arrow-icon"></i>
          </div>
          <div
            className="whatsappicon"
            role="button"
            onClick={() => {
              const isMobile = /iPhone|iPad|iPod|Android/i.test(
                navigator.userAgent
              );
              const whatsappUrl =
                "whatsapp://send?text=Hi team Printlozy&phone=9988007944";

              if (isMobile) {
                window.location.href = whatsappUrl;
              } else {
                window.open(
                  "whatsapp://send?text=Hi team Printlozy&phone=9988007944",
                  "_blank"
                );
              }
            }}
          >
            <i className="fa-brands fa-square-whatsapp"></i>
          </div>
        </div>
        <div
          className="modal fade"
          id="customizeProductModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Customize Product
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h6>Upload Image</h6>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={handleImageChange}
                />
                {image && (
                  <img
                    src={image}
                    alt="Preview"
                    className="mt-3"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                <h6 className="mt-3">Product Description</h6>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder="Enter product details..."
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Close
                </button>

                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        {data?.p_desc ? (
          <>
            <h6 className="textColor">Short description : </h6>
            <div
              dangerouslySetInnerHTML={{ __html: data.p_desc }}
              style={{ marginTop: 20 }}
            />
          </>
        ) : null}
        {data.description ? (
          <>
            <h6 className="textColor">Product description : </h6>
            <div
              dangerouslySetInnerHTML={{ __html: data.description }}
              style={{ marginTop: 20 }}
            />
          </>
        ) : null}{" "}
        <div className="addbutton">
          <div>
            {!isLoggedIn ? (
              <button
                type="button"
                className="btn btn-outline-success addtocart"
                onClick={() => {
                  addCart(data);
                }}
              >
                Add to Cart
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-success addtocart"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Add to Cart
              </button>
            )}
          </div>
          <div>
            {!isLoggedIn ? (
              <button
                type="button"
                className="btn btn-outline-success buynow"
                onClick={() => {
                  addCart(data);
                }}
              >
                Buy Now
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-success buynow"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Buy Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingelProducts;
