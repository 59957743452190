import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import googleplay from "../asset/pngImage/googleplay.png";
import applestore from "../asset/pngImage/applestore.png";
export default function Footer() {
  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="row g-2  text-start">
          <div className="col-md-4">
            <h6>Contact Us</h6>
            <div className="footerdiv">
              <Link className="LinkColor">
                <i className="fas fa-map-marker-alt textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  Hallo Majra, Chandigarh - 160002, India
                </span>
              </Link>
              <Link className="LinkColor" to="mailto:support@printlozy.com">
                <i className="fas fa-envelope textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  support@printlozy.com
                </span>
              </Link>
              <Link className="LinkColor" to="tel:+91 9988007944">
                <i className="fas fa-phone textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  +91 9988007944
                </span>
              </Link>
            </div>
            <hr className="hrTag" />
          </div>
          <div className="col-md-4">
            <h6>Useful Links</h6>
            <div className="footerdiv">
              <Link className="LinkColor" to="/">
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Home
                </span>
              </Link>
              <Link className="LinkColor" to="/shipping&refundPolicy">
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Shipping & Refund Policy
                </span>
              </Link>
              <Link className="LinkColor" to="/term&Condition">
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Terms & Condition
                </span>
              </Link>
              <Link className="LinkColor" to="/privacy-policy">
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Privacy Policy
                </span>
              </Link>
            </div>
            <hr className="hrTag" />
          </div>
          <div className="col-md-4">
            <h6>Follow Us Now</h6>
            <div className="footer_social_icons">
              <Link className="LinkColor" to="#">
                <i className="fa-brands fa-facebook-f textColor"></i> Facebook
              </Link>
              <Link className="LinkColor" to="#">
                <i className="fa-brands fa-twitter textColor"></i> Twitter
              </Link>
              <Link className="LinkColor" to="#">
                <i className="fa-brands fa-instagram textColor"></i> Instagram
              </Link>
              <Link className="LinkColor" to="#">
                <i className="fa-brands fa-pinterest-p textColor"></i> Pinterest
              </Link>
              <Link className="LinkColor" to="#">
                <i className="fa-brands fa-linkedin-in textColor"></i> linkedin
              </Link>
            </div>
            <hr className="hrTag" />
          </div>
        </div>
        <hr />
        <div className="text-center">
          <i className="fa-regular fa-copyright"></i>
          <Link
            className="LinkColor"
            to="https://www.printlozy.com/"
            target="_blank"
          >
            <span className="LinkColor">
              2024 Printlozy.co By Kalkar Technologies.
            </span>
          </Link>
          <span> All Rights Reserved.</span>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Please Login
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                You must login first to add to cart
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <Link className="nav-link " to="/login">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </footer>
  );
}
