import React from "react";
import { ImgURL } from "../url/api";
import shipping from "../asset/printlozy/SHIPPING.png";
import free from "../asset/printlozy/FREE DELIVERY.png";
import india from "../asset/printlozy/made in india.png";
import quality from "../asset/printlozy/premium.png";

function CompanyDetails() {
  const imageUrlForWeb = `${ImgURL}/images/sliders/1692253380.jpeg`;
  const imageUrlForMobile = `${ImgURL}/images/sliders/1692258864.png`;

  const determineImageUrl = () => {
    // You can adjust the breakpoint value based on your design requirements
    const breakpoint = 600; // For example, consider screens narrower than 600px as mobile

    // Check the viewport width
    const isMobile = window.innerWidth < breakpoint;

    // Return the appropriate image URL
    return isMobile ? imageUrlForMobile : imageUrlForWeb;
  };
  return (
    <div className="px-4 text-center">
      <div className="row gx-5 features">
        <div className="col-md-3">
          <div className="feature-box">
            <img src={shipping} alt="SHIPPING" />
            <div className="feature-title">SHIPPING WITHIN 48 HOURS</div>
            <div className="feature-subtitle">
              Shipping within 5 to 7 days
              <br />
              Your order will be shipped within 5 to 7 days from
              <br /> the time since order is placed!
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="feature-box">
            <img src={free} alt="SHIPPING" />
            <div className="feature-title">FREE DELIVERY</div>
            <div className="feature-subtitle">on Pre-paid orders.</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="feature-box">
            <img src={india} alt="SHIPPING" />
            <div className="feature-title">MADE IN INDIA</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="feature-box">
            <img src={quality} alt="SHIPPING" />
            <div className="feature-title">PREMIUM QUALITY</div>
            <div className="feature-subtitle">Reasonable price</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CompanyDetails;
