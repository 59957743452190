import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

const TermCondition = () => {
  const sectionRef = useRef(null);
  const currentPageUrl = window.location.href;
  // useEffect(() => {
  //   if (window.location.hash === "#term-condition") {
  //     sectionRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);
  return (
    <div
      className="container shippingpolicy"
      style={{ marginTop: 65, textAlign: "justify" }}
      ref={sectionRef}
    >
      {/* <Helmet>
        <title>Terms & Conditions</title>
        <meta property="og:title" content={"Terms & Conditions"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>*/}
      <br />
      <h4 className="textColor text-center mt-5">Terms and Conditions</h4>
      <hr />
      <p>
        <h6>Printlozy operated by Ajmal Hussain</h6>
        <br />
        Access to and use of Printlozy.com and the products and services
        available through the website are subject to the following terms,
        conditions, and notices (“Terms of Service"). By browsing through these
        Terms of Service and using the services provided by our website (
        <a className="text-decoration-none " href="https://www.printlozy.com">
          www.printlozy.com
        </a>
        ), you agree to all Terms of Service, along with the Privacy Policy on
        our website, which may be updated by us from time to time. Please check
        this page regularly to take notice of any changes we may have made to
        the Terms of Service. We reserve the right to review, withdraw, or amend
        the services without notice. We will not be liable if for any reason
        this Website is unavailable at any time or for any period. From time to
        time, we may restrict access to some parts of this Website or the entire
        Website.
        <br />
        <br />
        <b>Privacy</b> <br />
        <br />
        Our Privacy Policy, incorporated by reference in these Terms of Service,
        sets out how we will use the personal information you provide us. By
        using this Website, you agree to be bound by the Privacy Policy and
        warrant that all data you provide is accurate and up to date.
        <br />
        <br />
        <b>The Exactness of the Product</b>
        <br />
        <br /> The images of the items shown on the website are for illustrative
        purposes only. Although we have made every effort to display the colors
        accurately, we cannot guarantee that your computer’s display of the
        colors accurately reflects the color of the items. Your items may vary
        slightly from those images. All sizes and measurements of items are
        approximate; however, we do make every effort to ensure they are as
        accurate as possible. We take all reasonable care to ensure that all
        details, descriptions, and prices of items are as accurate as possible.
        <br />
        <br />
        <b>Pricing</b>
        <br />
        <br /> We ensure that all details of prices appearing on the website are
        accurate; however, errors may occur. If we discover an error in the
        price of any goods which you have ordered, we will inform you of this as
        soon as possible. If we are unable to contact you, we will treat the
        order as canceled. If you cancel and you have already paid for the
        goods, you will receive a full refund. Additionally, prices for items
        may change from time to time without notice. However, these changes will
        not affect orders that have already been dispatched. The price of an
        item includes GST (or similar sales tax) at the prevailing rate, for
        which we are responsible as a seller. Please note that the prices listed
        on the website are only applicable for items purchased on the website
        and not through any other source.
        <br />
        <br />
        <b> Discounts and Vouchers</b> <br />
        <br />
        Any amount of voucher or discount deducted at the time of placing the
        order, deducted amount from the order value shall not be refunded on any
        cancellation or return of the order.
        <br />
        <br />
        <b> Payment</b>
        <br />
        <br /> Upon receiving your order, we carry out a standard
        pre-authorization check on your payment card to ensure there are
        sufficient funds to fulfill the transaction. Goods will not be
        dispatched until this pre-authorization check has been completed. Your
        card will be debited once the order has been accepted.
        <br />
        <br /> <b>Available payment options:</b>
        <br />
        <br /> • UPI
        <br /> <br />• Debit card/Credit Card
        <br />
        <br /> • Cash on Delivery
        <br />
        <br /> • Net Banking <br />
        <br />
        <b> Cash on Delivery charges (COD Charges)</b>
        <br />
        <br /> COD charges of flat ₹100 will apply if the payment mode chosen is
        cash on delivery. If your payment is debited from your account after a
        payment failure, it is normally rolled back to your bank account within
        7 business days. For any further clarification, you can email us at
        support@printlozy.com.
        <br />
        <br />
        <b>Violation & Termination</b> <br />
        <br />
        You agree that the Company may, in its sole discretion and without prior
        notice, terminate your access to the website and block your future
        access, if we determine that you have violated these Terms of Service or
        any other policies. If you or the Company terminates your use of any
        service, you shall still be liable to pay for any service that you have
        already ordered. For any further questions, comments, or requests
        regarding our Terms of Service or the website, please contact us at
        support@printlozy.com.
        <br />
        <br />
        <b>Order Status</b> <br />
        <br />
        You can check the status of your order by simply going to “order
        history” and referring to the status column for the status.
        <br />
        <br />
        <b> Service & Complaints</b>
        <br />
        <br /> The satisfaction of our members is close to our hearts.
        Therefore, we endeavor to address your concerns as quickly as possible
        and always provide you with our feedback after receiving your input. For
        service inquiries, please contact our customer service department at
        support@printlozy.com.
        <br />
        <br />
        <b>Incorrect Order or Product Damaged When Received</b> <br />
        <br />
        We take utmost care while packing your order to ensure that the right
        product reaches you in the safest way. However, in case you receive an
        incorrect or damaged product, it will be replaced. The process is
        hassle-free. In such cases, all you would have to do is notify us on the
        same day of receiving the order by writing to us at
        support@printlozy.com or calling us at the contact number mentioned on
        the Contact Us page, and your request will be taken care of. In case you
        fail to notify us on the same day of receiving an incorrect or damaged
        product, the product will be considered sold and will not be replaced or
        refunded.
        <br />
        <br />
        <b>Returns</b>
        <br />
        <br /> No item purchased online can be returned at the store under any
        circumstances.
        <br />
        <br />
        <b>Disclaimer of Guarantee</b>
        <br />
        <br /> We do not guarantee, represent, or warrant that your use of our
        service will be uninterrupted, timely, secure, or error-free. You agree
        that, from time to time, we may remove the service for indefinite
        periods or cancel the service at any time, without any prior notice. You
        expressly agree that your use of, or inability to use, the service is at
        your sole risk. All products and services delivered to you through the
        service are (except as expressly stated by us) provided ‘as is’ and ‘as
        available’ for your use, without any representation, warranties, or
        conditions of any kind, either express or implied, including all implied
        warranties or conditions of merchantability, merchantable quality,
        fitness for a particular purpose, durability, and title.
        <br />
        <br />
        <b> Reg Address:</b>
        <br />
        <br />
        <i>
          Printlozy a Brand by - Kalakar Technologies <br />
          #791, Near Dispensary, Hallo Majra, Chandigarh - 160002
        </i>
        <br />
        <br />
        <b> Printlozy Website is Governed by Indian Law Liability</b> <br />
        <br />
        You will not hold Printlozy responsible for other users' content,
        actions, inactions, or collaborations. We have no control over and do
        not guarantee the quality, safety, or legality of the collaborations
        advertised, the truth or accuracy of users' content, the ability of
        parties to deliver on collaborations, or that the parties will complete
        a transaction. We cannot guarantee continuous or secure access to our
        services, and the operation of the Website may be interfered with by
        numerous factors outside of our control. Accordingly, to the extent
        legally permitted, we exclude all implied warranties, terms, and
        conditions related to the use thereof. We are not liable for any loss of
        money, goodwill, or reputation, or any special, indirect, or
        consequential damages arising out of your use of the Website and
        services. Some jurisdictions do not allow the disclaimer of warranties
        or exclusion of damages, so such disclaimers and exclusions may not
        apply to you.
        <br />
        <br />
        You must ensure that your access to the Website and Printlozy services
        is not illegal or prohibited by laws that apply to you. You must take
        precautions to ensure that the process you employ for accessing
        Printlozy's services does not expose you to the risk of viruses,
        malicious computer code, or any other form of interference that may
        damage your computer system. We do not accept responsibility for any
        interference or damage to any computer system that arises in connection
        with your use of the Website or any linked website.
        <br />
        <br /> We do not accept liability for any losses arising directly or
        indirectly from a failure to provide any service, corruption to or loss
        of data, errors or interruptions, any suspension or discontinuance of
        service, or any transmissions by others in contravention of the
        registered members' obligations as set out in these Terms of Service.
        You acknowledge that we may not be able to confirm the identity of other
        registered users or prevent them from acting under pretenses or in a
        manner that infringes on the rights of any person.
        <br />
        <br />
      </p>
    </div>
  );
};

export default TermCondition;
