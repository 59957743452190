import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { ImgURL, URL } from "../url/api";
import ActivityIndicator from "../component/ActivityIndicator";
import { toast } from "react-toastify";
import end_point from "../endpoints/apiendpoints";

const ProfileDetails = () => {
  const [Token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        profileData(token);
        setToken(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const profileData = (session) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_PROFILE}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setLoading(false);
        setData(userData.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  const setProfile = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("image", image || "");
    formdata.append("name", name);
    formdata.append("email", email);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}${end_point.UPDATE_PROFILE}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setLoading(false);
        profileData(Token);
        const changeData = newData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const nameEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const imageInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);

      // Read file as data URL using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Store the image data URL for preview
      };
      reader.readAsDataURL(file);
    }
  };
  const handleeditaddress = (e) => {
    setName(e.name);
    setEmail(e.email);
  };
  const resetform = (e) => {
    setName("");
    setEmail("");
    setImage("");
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <>
      <div className="card p-2">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-2">
            <div className="m-2">
              {data[0]?.image && (
                <img
                  src={`${ImgURL}${data[0]?.image}`}
                  alt="Image"
                  className="profileimage"
                />
              )}
            </div>
          </div>
          <div className="col-md-8">
            <div className="m-2">
              {data[0]?.name && `Name: ${data[0]?.name}`}
            </div>
            <div className="m-2">
              {data[0]?.email && `Email: ${data[0]?.email}`}
            </div>
            <div className="m-2">
              {data[0]?.contact && `Mobile: ${data[0]?.contact}`}
            </div>
          </div>
          <div className="col-md-2">
            <i
              className="fa fa-user-pen m-2 iconClass"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#EditProfile"
              onClick={() => {
                handleeditaddress(data[0]);
              }}
            ></i>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="EditProfile"
        tabIndex="-1"
        aria-labelledby="EditProfileLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditProfileLabel">
                Edit Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  resetform();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  aria-label="First name"
                  required
                  value={name}
                  onChange={nameInputChange}
                />
              </div>
              <div className="col my-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  required
                  value={email}
                  onChange={nameEmailChange}
                />
              </div>
              <div className="col my-4">
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={imageInputChange}
                />
              </div>
              {imagePreview && (
                <div className="image-preview my-4">
                  <img
                    src={imagePreview} // Use the data URL as image source
                    alt="Preview"
                    className="img-fluid rounded"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  resetform();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setProfile();
                }}
                data-bs-dismiss="modal"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
