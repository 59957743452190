import React, { useState, useEffect } from "react";
import { URL, ImgURL, rupees_icon } from "../url/api";
import Cookies from "js-cookie";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import end_point from "../endpoints/apiendpoints";
import Logo from "../asset/pngImage/logo.png";
import product from "../asset/printlozy/product.png";
import Modal from "bootstrap/js/dist/modal";
import {
  removeItemToCart,
  reduceItemToCart,
  addProducts,
  clearAllData,
  setCart,
} from "../reduxSetup/slices/productsSlice";
function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [Token, setToken] = useState("");
  const [profile_Data, setData] = useState("");
  const [checkoutcartdata, setCheckoutcartdata] = useState(null);
  const [selectedAddress, setselectedAddress] = useState(null);
  const [allAddress, setallAddress] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [Address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [alternateno, setAlternateno] = useState("");
  const [landmark, setLandmark] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [addressId, setAddressID] = useState("");
  const [Coupon, setCoupon] = useState("");
  const [applycoupondata, setApplycoupondata] = useState("");
  const [coupon_code, setCoupon_code] = useState("");
  const [SelectCoupon, setSelectCoupon] = useState("");
  const [paymode, setPaymode] = useState("online");

  useEffect(() => {
    checkLogin();
    loadCartFromLocalStorage();
  }, []);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
        loadCartFromLocalStorage();
        profileData(token);
        getCustomerAddresses(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const indianStates = [
    { key: "andamanAndNicobarIslands", value: "Andaman and Nicobar Islands" },
    { key: "andhraPradesh", value: "Andhra Pradesh" },
    { key: "arunachalPradesh", value: "Arunachal Pradesh" },
    { key: "assam", value: "Assam" },
    { key: "bihar", value: "Bihar" },
    { key: "chandigarh", value: "Chandigarh" },
    { key: "chhattisgarh", value: "Chhattisgarh" },
    { key: "delhi", value: "Delhi" },
    { key: "goa", value: "Goa" },
    { key: "gujarat", value: "Gujarat" },
    { key: "haryana", value: "Haryana" },
    { key: "himachalPradesh", value: "Himachal Pradesh" },
    { key: "jharkhand", value: "Jharkhand" },
    { key: "karnataka", value: "Karnataka" },
    { key: "kerala", value: "Kerala" },
    { key: "lakshadweep", value: "Lakshadweep" },
    { key: "madhyaPradesh", value: "Madhya Pradesh" },
    { key: "maharashtra", value: "Maharashtra" },
    { key: "manipur", value: "Manipur" },
    { key: "meghalaya", value: "Meghalaya" },
    { key: "mizoram", value: "Mizoram" },
    { key: "nagaland", value: "Nagaland" },
    { key: "odisha", value: "Odisha" },
    { key: "punjab", value: "Punjab" },
    { key: "rajasthan", value: "Rajasthan" },
    { key: "sikkim", value: "Sikkim" },
    { key: "tamilNadu", value: "Tamil Nadu" },
    { key: "telangana", value: "Telangana" },
    { key: "tripura", value: "Tripura" },
    { key: "uttarPradesh", value: "Uttar Pradesh" },
    { key: "uttarakhand", value: "Uttarakhand" },
    { key: "westBengal", value: "West Bengal" },
    { key: "puducherry", value: "Puducherry" },
  ];
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const loadCartFromLocalStorage = async () => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      dispatch(setCart(parsedCart));
      setCheckoutcartdata(parsedCart);
      calculateCartTotals(parsedCart);
    }
  };
  const calculateCartTotals = (cartItems, couponData = null) => {
    let totalOriginalPrice = 0;
    let totalDiscountedPrice = 0;
    let totalSavings = 0;

    // Calculate item-wise totals
    const itemCalculations = cartItems.map((item) => {
      const variant = item.variant[0];
      const { p_price, p_mrp } = variant;
      const { qty } = item;
      const originalPrice = p_mrp * qty;
      const discountedPrice = p_price * qty;
      const savings = Math.max((p_mrp - p_price) * qty, 0); // Ensure savings are not negative
      totalOriginalPrice += originalPrice;
      totalDiscountedPrice += discountedPrice;
      totalSavings += savings;

      return {
        product_id: item.product_id,
        qty,
        originalPrice,
        discountedPrice,
        savings,
      };
    });

    // Apply coupon if available
    let couponDiscount = 0;
    if (couponData) {
      if (couponData.type === "flat") {
        couponDiscount = parseFloat(couponData.coupon_price);
      }
      // Ensure coupon discount doesn't exceed total
      couponDiscount = Math.min(couponDiscount, totalDiscountedPrice);
      totalDiscountedPrice -= couponDiscount;
    }

    // Ensure grand total doesn't fall below zero
    totalDiscountedPrice = Math.max(totalDiscountedPrice, 0);

    // Grand total
    const grandTotal = totalDiscountedPrice;

    return {
      itemCalculations,
      totalOriginalPrice,
      totalSavings: Math.max(totalSavings, 0), // Ensure total savings are not negative
      couponDiscount: Math.max(couponDiscount, 0), // Ensure coupon discount is not negative
      grandTotal,
    };
  };

  const [totals, setTotals] = useState({
    itemTotal: 0,
    itemSaving: 0,
    couponSaving: 0,
    payableAmount: 0,
  });
  useEffect(() => {
    if (checkoutcartdata) {
      const couponData = applycoupondata || null;
      const {
        itemCalculations,
        totalOriginalPrice,
        totalSavings,
        couponDiscount,
        grandTotal,
      } = calculateCartTotals(checkoutcartdata, couponData);

      setTotals({
        itemTotal: totalOriginalPrice,
        itemSaving: totalSavings,
        couponSaving: couponDiscount,
        payableAmount: grandTotal,
      });
    }
  }, [checkoutcartdata, applycoupondata]);
  const handleImageError = (event) => {
    event.target.src = product; // Use the logo variable from your imports
  };
  const getCustomerAddresses = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setallAddress(userData.data);
        const useraddress = userData.data.filter((item) => item.id == id);
        setselectedAddress(useraddress[0]);
      })
      .catch((error) => console.log("error", error));
  };
  const addCustomerAddress = () => {
    if (!name) {
      showToastErr("Contact person name is required");
      return;
    }
    if (!email) {
      showToastErr("Email is required");
      return;
    }
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      showToastErr("Enter a valid 10-digit mobile number");
      return;
    }
    if (!Address) {
      showToastErr("Address is required");
      return;
    }
    if (alternateno && !/^\d{10}$/.test(alternateno)) {
      showToastErr("Enter a valid 10-digit alternate number");
      return;
    }
    if (!city) {
      showToastErr("City is required");
      return;
    }
    if (!zip || !/^\d{6}$/.test(zip)) {
      showToastErr("Enter a valid 6-digit pincode");
      return;
    }
    if (!state) {
      showToastErr("State is required");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("address", Address);
    formdata.append("city", city);
    formdata.append("email", email);
    formdata.append("state", state);
    formdata.append("pincode", zip);
    formdata.append("contact_person", name);
    formdata.append("mobile_no", phoneNumber);
    formdata.append("landmark", landmark);
    formdata.append("alternate_no", alternateno);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.ADD_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.success === true) {
          getCustomerAddresses(Token);
          closeModal("add_update_address");
          resetform();
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const updateCustomerAddress = () => {
    if (!name) {
      showToastErr("Contact person name is required");
      return;
    }
    if (!email) {
      showToastErr("Email is required");
      return;
    }
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      showToastErr("Enter a valid 10-digit mobile number");
      return;
    }
    if (!Address) {
      showToastErr("Address is required");
      return;
    }
    if (alternateno && !/^\d{10}$/.test(alternateno)) {
      showToastErr("Enter a valid 10-digit alternate number");
      return;
    }
    if (!city) {
      showToastErr("City is required");
      return;
    }
    if (!zip || !/^\d{6}$/.test(zip)) {
      showToastErr("Enter a valid 6-digit pincode");
      return;
    }
    if (!state) {
      showToastErr("State is required");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("address", Address);
    formdata.append("address_id", addressId);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", zip);
    formdata.append("contact_person", name);
    formdata.append("mobile_no", phoneNumber);
    formdata.append("landmark", landmark);
    formdata.append("alternate_no", alternateno);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.UPDATE_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.success === true) {
          getCustomerAddresses(Token);
          closeModal("add_update_address");
          setIsUpdating(false);
          setAddressID("");
          resetform();
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const deleteCustomerAddress = (item) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("id", item.id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.DELETE_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.success === true) {
          getCustomerAddresses(Token);
          closeModal("add_update_address");
          resetform();
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const phoneInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 10) {
      setPhoneNumber(inputPhoneNumber);
    }
  };
  const landmarkInputChange = (e) => {
    const inputLandmark = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputLandmark.length <= 10) {
      setLandmark(inputLandmark);
    }
  };
  const emailInputChange = (e) => {
    setEmail(e.target.value);
  };
  const alterateInputChange = (e) => {
    const inputAlternateNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputAlternateNumber.length <= 10) {
      setAlternateno(inputAlternateNumber);
    }
  };
  const AddressInputChange = (e) => {
    setAddress(e.target.value);
  };
  const cityInputChange = (e) => {
    setCity(e.target.value);
  };
  const zipInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 6) {
      setZip(inputPhoneNumber);
    }
  };
  const stateInputChange = (e) => {
    setState(e.target.value);
  };
  const couponInputChange = (e) => {
    setCoupon_code(e.target.value);
  };
  const Address_Update = (item) => {
    setIsUpdating(true);
    setAddressID(item.id);
    setName(item.contact_person || "");
    setPhoneNumber(item.mobile_no || "");
    setLandmark(item.landmark || "");
    setEmail(item.email || "");
    setAlternateno(item.alternate_no || "");
    setAddress(item.address || "");
    setCity(item.city || "");
    setZip(item.pincode || "");
    setState(item.state || "");
    openModal("add_update_address");
  };
  const handleSubmit = () => {
    if (isUpdating) {
      console.log("Updating address...");
      updateCustomerAddress();
    } else {
      console.log("Adding new address...");
      addCustomerAddress();
    }
    resetform();
  };
  const resetform = () => {
    setIsUpdating(false);
    setName("");
    setPhoneNumber("");
    setAddress("");
    setCity("");
    setEmail("");
    setState("");
    setZip("");
    setLandmark("");
    setAddressID("");
    setAlternateno("");
  };
  const openModal = (modalId) => {
    const modalElement = document.getElementById(modalId);
    const modal = new Modal(modalElement);
    modal.show();
  };
  const closeModal = (modalId) => {
    const modalElement = document.getElementById(modalId);
    const modal = Modal.getInstance(modalElement);
    modal.hide();
  };
  const get_coupon = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_COUPON}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setCoupon(userData.data);
      })
      .catch((error) => console.log("error", error));
  };
  const apply_coupon = (code) => {
    if (!code && !coupon_code) {
      showToastErr("Enter your coupon code");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("coupon_code", coupon_code ? coupon_code : code);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.APPLY_COUPON}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          const userData = result;
          if (userData.error === false) {
            setApplycoupondata(userData.data);
            calculateCartTotals(checkoutcartdata, userData.data);
            showToast(`Coupon applied successfully`);
            setCoupon_code("");
            // setSelectCoupon("");
            closeModal("couponmodal");
          } else {
            showToastErr("Failed to apply the coupon. Please try again.");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        showToastErr("An error occurred while applying the coupon.");
      });
  };
  const profileData = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_PROFILE}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setData(userData.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const checkout_api = () => {
    if (!selectedAddress || !selectedAddress.address) {
      showToastErr("Billing address is required");
      return;
    }
    if (!selectedAddress.city) {
      showToastErr("Billing city is required");
      return;
    }
    if (!selectedAddress.state) {
      showToastErr("Billing state is required");
      return;
    }
    if (!selectedAddress.pincode || !/^\d{6}$/.test(selectedAddress.pincode)) {
      showToastErr("Enter a valid 6-digit billing pincode");
      return;
    }
    if (!selectedAddress.contact_person) {
      showToastErr("Billing contact person name is required");
      return;
    }
    if (
      !selectedAddress.mobile_no ||
      !/^\d{10}$/.test(selectedAddress.mobile_no)
    ) {
      showToastErr("Enter a valid 10-digit billing mobile number");
      return;
    }
    if (
      selectedAddress.alternate_no &&
      !/^\d{10}$/.test(selectedAddress.alternate_no)
    ) {
      showToastErr("Enter a valid 10-digit alternate number");
      return;
    }
    if (
      !totals ||
      typeof totals.payableAmount !== "number" ||
      totals.payableAmount <= 0
    ) {
      showToastErr("Payable amount must be valid and greater than zero");
      return;
    }
    if (!paymode) {
      showToastErr("Payment mode is required");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("bill_address", selectedAddress.address);
    formdata.append("bill_email", profile_Data[0].email);
    formdata.append("bill_city", selectedAddress.city);
    formdata.append("bill_state", selectedAddress.state);
    formdata.append("bill_pincode", selectedAddress.pincode);
    formdata.append("bill_name", selectedAddress.contact_person);
    formdata.append("bill_mobile", selectedAddress.mobile_no);
    formdata.append("bill_alternate_no", selectedAddress.alternate_no);
    formdata.append("coupon", applycoupondata.code || "");
    formdata.append(
      "payable_amt",
      paymode === "cod" ? totals.payableAmount + 100 : totals.payableAmount
    );
    formdata.append("payment_mode", paymode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.CHECKOUT}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("checkout result", result);
        const userData = JSON.parse(result);
        if (userData.error === false) {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
          dispatch(clearAllData());
          localStorage.removeItem("cart");
          getCart();
          loadCartFromLocalStorage();
          navigate(`/OrderPlaced/${userData?.order_id}`);
        } else {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CART}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <div className="container-fluid checkoutpage mt-5">
      <div className="row pt-5">
        <div className="col-md-8">
          <>
            <h5 className="mx-3">Products</h5>
            <div className="productdiv">
              {checkoutcartdata && checkoutcartdata.length > 0 ? (
                checkoutcartdata.map((item) => (
                  <div
                    key={item.id}
                    className="card m-3 p-2 d-flex flex-row justify-content-between align-items-center"
                  >
                    <div className="productcard_img">
                      <img
                        key={item.id}
                        alt={item.product[0].p_name}
                        src={`${ImgURL}product_image/${item.product[0].p_image[0]}`}
                        loading="lazy"
                        onError={handleImageError}
                        className="card-img-top mx-auto checkoutimage"
                      />
                    </div>
                    <div className="productcard_text">
                      <h6>{item.product[0]?.p_name || "Product Name"}</h6>
                      <p>
                        {item.product[0]?.p_sub_title || "Product Subtitle"}
                      </p>
                      <p>QTY: {item.qty || 1}</p>
                    </div>
                    <div className="productcard_price text-center">
                      <h6>
                        <span
                          className="text-muted"
                          style={{
                            textDecoration: "line-through",
                            marginRight: "5px",
                          }}
                        >
                          {rupees_icon}
                          {item.variant[0]?.p_mrp || 0}
                        </span>
                        <span className="text-success fw-bold">
                          {rupees_icon}
                          {item.variant[0]?.p_price || 0}
                        </span>
                      </h6>
                      <p>
                        <small className="text-danger fw-normal">
                          Save {rupees_icon}
                          {Math.max(
                            (item.variant[0]?.p_mrp || 0) -
                              (item.variant[0]?.p_price || 0),
                            0
                          ).toFixed(2)}{" "}
                          (
                          {Math.max(
                            (
                              (((item.variant[0]?.p_mrp || 0) -
                                (item.variant[0]?.p_price || 0)) /
                                (item.variant[0]?.p_mrp || 1)) *
                              100
                            ).toFixed(0),
                            0
                          )}
                          % Off)
                        </small>
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="card p-3">
                  <h3 className="text-center">No Product Available in Cart</h3>
                </div>
              )}
            </div>
          </>
          <>
            <h5 className="mx-3">Address</h5>
            {selectedAddress ? (
              <div className="card m-3 p-2 d-flex flex-row justify-content-between align-items-center">
                <div className="address-text">
                  <div className="d-flex flex-row align-items-center">
                    <i className="fa fa-user mr-2"></i>
                    <span>{selectedAddress.contact_person}</span>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <i className="fa fa-map-marker-alt mr-2"></i>
                    <span>
                      {selectedAddress.address}, {selectedAddress.city},{" "}
                      {selectedAddress.landmark}, {selectedAddress.state},{" "}
                      {selectedAddress.pincode}
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <i className="fa fa-phone-alt mr-2"></i>
                    <span>{selectedAddress.mobile_no}</span>
                  </div>
                </div>
                <button
                  className="change-address"
                  onClick={() => openModal("addressModal")}
                >
                  Change Address
                </button>
              </div>
            ) : (
              <>
                {allAddress?.map((item) => {
                  return (
                    <div className="card m-3 p-2 d-flex flex-row justify-content-between align-items-center">
                      <div className="address-text">
                        <div className="d-flex flex-row align-items-center">
                          <i className="fa fa-user mr-2"></i>
                          <span>{item.contact_person}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                          <i className="fa fa-map-marker-alt mr-2"></i>
                          <span>
                            {item.address}, {item.city}, {item.landmark},{" "}
                            {item.state}, {item.pincode}
                          </span>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                          <i className="fa fa-phone-alt mr-2"></i>
                          <span>{item.mobile_no}</span>
                        </div>
                      </div>
                      <button
                        className="change-address"
                        onClick={() => openModal("addressModal")}
                      >
                        Change Address
                      </button>
                    </div>
                  );
                })}
              </>
            )}
          </>
        </div>
        {/* select new address modal */}
        <div
          className="modal fade"
          id="addressModal"
          tabIndex="-1"
          aria-labelledby="addressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <button
                  type="button"
                  className="btn btn-primary mx-3"
                  onClick={() => {
                    closeModal("addressModal");
                    openModal("add_update_address");
                    resetform();
                  }}
                >
                  + Add Address
                </button>
                <>
                  {allAddress?.map((item) => {
                    return (
                      <div
                        role="button"
                        key={item.id} // Add a unique key for each address
                        className={`m-3 p-2 d-flex flex-row justify-content-between align-items-center ${
                          selectedAddress?.id === item.id
                            ? "selectedcard"
                            : "card"
                        }`}
                      >
                        <div
                          className="address-text"
                          onClick={() => setselectedAddress(item)}
                        >
                          <div className="d-flex flex-row align-items-center">
                            <i className="fa fa-user mr-2"></i>
                            <span>{item.contact_person}</span>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <i className="fa fa-map-marker-alt mr-2"></i>
                            <span>
                              {item.address}, {item.city}, {item.landmark},
                              {item.state}, {item.pincode}
                            </span>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <i className="fa fa-phone-alt mr-2"></i>
                            <span>{item.mobile_no}</span>
                          </div>
                        </div>
                        <div className="address-update_icon">
                          <i
                            className="fa-solid fa-pencil-square"
                            onClick={() => {
                              Address_Update(item);
                            }}
                          ></i>
                          <i
                            className="fa-solid fa-trash"
                            onClick={() => deleteCustomerAddress(item)}
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                </>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => closeModal("addressModal")}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => closeModal("addressModal")}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* add new address modal */}
        <div
          className="modal fade"
          id="add_update_address"
          tabIndex="-1"
          aria-labelledby="add_addressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => closeModal("add_update_address")}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        defaultValue={name ? name : ""}
                        className="form-control"
                        placeholder="First name"
                        aria-label="First name"
                        id="userName"
                        required
                        onChange={nameInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        defaultValue={email ? email : ""}
                        className="form-control"
                        placeholder="Enter Email"
                        aria-label="Enter Email"
                        id="email"
                        required
                        onChange={emailInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-phone-alt"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={phoneNumber ? phoneNumber : ""}
                        placeholder="Phone Number"
                        aria-label="Phone number"
                        required
                        onChange={phoneInputChange}
                        onKeyPress={(event) => {
                          if (event.target.value.length >= 10) {
                            event.preventDefault(); // Prevent entering more characters
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={Address ? Address : ""}
                        placeholder="Address"
                        aria-label="Address"
                        required
                        onChange={AddressInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-map-signs"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={landmark ? landmark : ""}
                        placeholder="Landmark"
                        aria-label="Landmark"
                        required
                        onChange={landmarkInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-phone-alt"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={alternateno ? alternateno : ""}
                        placeholder="Alternate Number"
                        aria-label="Alternate number"
                        required
                        onChange={alterateInputChange}
                        onKeyPress={(event) => {
                          if (event.target.value.length >= 10) {
                            event.preventDefault(); // Prevent entering more characters
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-6 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-city"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={city ? city : ""}
                        placeholder="City"
                        aria-label="City"
                        required
                        onChange={cityInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-6 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-location-arrow"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={zip ? zip : ""}
                        placeholder="Zip"
                        aria-label="Zip"
                        required
                        onChange={zipInputChange}
                        onKeyPress={(event) => {
                          if (event.target.value.length >= 6) {
                            event.preventDefault(); // Prevent entering more characters
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 my-2">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-flag"></i>
                        </span>
                      </div>
                      <select
                        className="form-control"
                        value={state || ""}
                        onChange={stateInputChange}
                        aria-label="State"
                        required
                      >
                        <option value="" disabled>
                          Select State
                        </option>
                        {indianStates.map((stateItem) => (
                          <option key={stateItem.key} value={stateItem.value}>
                            {stateItem.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    closeModal("add_update_address");
                    openModal("addressModal");
                    resetform();
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  {isUpdating ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <h5>Apply Coupon</h5>
          <div className="card my-3 couponcard">
            {applycoupondata != "" ? (
              applycoupondata.code
            ) : (
              <div className="d-flex align-items-center">
                <i className="fas fa-gift"></i>
                <span>APPLY COUPON</span>
              </div>
            )}
            <span
              role="button"
              onClick={() => {
                openModal("couponmodal");
                get_coupon();
              }}
            >
              Select
            </span>
          </div>
          <h5>Order Details</h5>
          <div className="card my-3 p-2 orderdetail">
            <div className="d-flex justify-content-between align-items-center">
              <p>Item Total</p>
              <p>
                {rupees_icon} {totals.itemTotal}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p>Item Saving</p>
              <p>
                {rupees_icon} {totals.itemSaving}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p>Coupon Saving</p>
              <p>
                {rupees_icon} {totals.couponSaving}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p>Delivery Fee</p>
              <p>
                Free {rupees_icon}
                <span className="text-decoration-line-through">50</span>
              </p>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-bold">Payable Amount</p>
              <p className="fw-bold">
                {rupees_icon}{" "}
                {paymode === "cod"
                  ? totals.payableAmount + 100
                  : totals.payableAmount}
              </p>
            </div>
          </div>
          <div className="card pay-mode-section">
            <h5 className="pay-mode-title">Payment Method</h5>
            <div className="pay-mode-options">
              <label className="pay-mode-option">
                <input
                  type="checkbox"
                  name="payMode"
                  value="online"
                  checked={paymode === "online"}
                  onChange={(e) => setPaymode(e.target.value)}
                />
                Online
              </label>
              <label className="pay-mode-option">
                <input
                  type="checkbox"
                  name="payMode"
                  value="cod"
                  checked={paymode === "cod"}
                  onChange={(e) => setPaymode(e.target.value)}
                />
                Cash on Delivery (COD)
              </label>
              <small>
                Note: On COD additional {rupees_icon}{" "}
                <span className="text-danger">100.00</span> fee will be added ={" "}
                {rupees_icon} {totals.payableAmount + 100}
              </small>
            </div>
          </div>
          <button
            className="btn btn-primary my-3 w-100"
            onClick={() => checkout_api()}
          >
            Checkout
          </button>
        </div>
        {/* coupon modal */}
        <div
          className="modal fade"
          id="couponmodal"
          tabIndex="-1"
          aria-labelledby="add_addressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => closeModal("couponmodal")}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-8 col-md-9">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-gift"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Code"
                        defaultValue={coupon_code ? coupon_code : ""}
                        aria-label="Enter Your Code"
                        id="userName"
                        required
                        onChange={couponInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-4 col-md-3">
                    <button
                      className="btn btn-primary w-100"
                      style={{ padding: "10px" }}
                      onClick={() => apply_coupon()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                {Coupon.length > 0
                  ? Coupon?.map((item) => {
                      return (
                        <div
                          className="card p-2 my-4 mx-1 codeui"
                          key={item.id}
                        >
                          <div>
                            <h6 className="card-title">Code: {item.code}</h6>
                            <p className="card-text">
                              {rupees_icon} {item.coupon_price}
                            </p>
                          </div>
                          <button
                            onClick={() => {
                              // setSelectCoupon(item.code);
                              // if (SelectCoupon != null) {
                              apply_coupon(item.code);
                              // }
                            }}
                            className="btn 
                              btn-outline-success"
                          >
                            Apply
                          </button>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
