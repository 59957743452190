import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  const sectionRef = useRef(null);
  const currentPageUrl = window.location.href;
  // useEffect(() => {
  //   if (window.location.hash === "#privacy-policy") {
  //     sectionRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);
  return (
    <div
      className="container shippingpolicy"
      style={{ marginTop: 65, textAlign: "justify" }}
      ref={sectionRef}
    >
      {/* <Helmet>
        <title>Privacy Policy</title>
        <meta property="og:title" content={"Privacy Policy"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>*/}
      <br />
      <h4 className="textColor text-center mt-5">Privacy Policy</h4>
      <p>
        <b> User’s Information and Privacy:</b>
        <br />
        <br /> We truly value the trust you place in us. Protecting personal
        data and your privacy is the greatest priority for us. This privacy
        policy is designed to ensure that your information remains confidential
        and secure. We don’t compromise with anything when it comes to
        protecting your rights of privacy on our systems, app, or site (
        <a href="https://www.printlozy.com">www.printlozy.com</a>). We may never
        exploit your personal information by selling it to third parties.
        However, we cannot be held accountable for any breaches caused by third
        parties or any unauthorized access that occurs beyond our control. We
        are committed to continually updating our security measures to safeguard
        your data.
        <br />
        <br />I authorize Printlozy to send me information, alerts, SMS
        messages, calls, and other communications to the telephone numbers
        provided, either directly or through third-party service providers. I
        understand that these numbers may or may not be registered with the
        National Do Not Call Registry or the National Customer Preference
        Register.
        <br />
        <br />I confirm that I will not hold Printlozy or its service providers
        liable or file complaints under the Telecom Commercial Communications
        Customer Preference (TRAI) Regulations, 2010, or any other applicable
        regulations, including any amendments.
        <br />
        <br /> <b>Cookies:</b>
        <br />
        <br />
        We use “cookies” to improve your experience on our website and to
        provide you with a safe, efficient, smooth, and customized experience.
        Cookies are little tags that we assign to your computer when you first
        visit our website. This helps us to recognize you when you return to the
        website. Personal Information provided by you is used only:
        <br />
        <br /> • To make your experience on our website smoother and easier
        <br />
        <br /> • To respond to your inquiries and fulfill your requests for
        information about our services
        <br /> <br />• To inform you about your orders, new products, and the
        latest promotional offers
        <br />
        <br /> • To improve the content and advertising on our website
        <br />
        <br /> • To keep you updated on changes to our website, its terms,
        conditions, and policies
        <br />
        <br /> • To personalize your user experience by presenting advertising,
        products, and offers that are tailored to you
        <br />
        <br /> • To help you resolve any issues you may encounter on the site,
        including technical problems
        <br />
        <br /> • To process your purchases and provide you with precise customer
        service (based on your purchase)
        <br /> <br />• To ensure the proper administration of our website.
        <br /> <br />• To resolve disputes, troubleshoot problems, and promote a
        safe and secure service
        <br /> <br />• To conduct internal reviews and data analysis to
        understand how our website is used and improve our services accordingly.
        <br />
        <br />
        <b>Security:</b>
        <br />
        <br />
        Your privacy is our priority. We’ve implemented physical,
        administrative, and technical safeguards to protect your personal
        information from unauthorized access. Also, our security measures
        include standard protocols and mechanisms to protect sensitive data like
        credit card details. In the unlikely event of a breach, we will notify
        you of it by email or fax and take immediate action to restore the
        integrity of our systems.
        <br />
        <br />
        <b>Advertising:</b>
        <br />
        <br />
        We also collaborate with some authorized third parties to manage a
        limited set of advertisements on our website and app. Rest assured, no
        personal information is shared during this process. We may use general
        profile information to ensure that the ads you see are relevant and
        useful to you.
        <br />
        <br />
        <b>Use of Services:</b>
        <br />
        <br />
        Your use of services on
        <a href="https://www.printlozy.com"> www.printlozy.com</a> and your
        provision of information in connection is entirely your choice. You will
        always have the option of whether to perform the transaction and provide
        this information or not. However, to create an online account or
        complete a transaction, some personal details will be required. The
        choice to proceed is entirely yours. If you choose to provide us with
        personal information, we will use that information to help us get you
        the product or information you have requested.
        <br />
        <br />
        <b>Important Disclaimer:</b>
        <br />
        <br />
        Although we always strive to provide full security and secrecy to your
        personal information, we urge you to be careful with sensitive
        information. Please do not share it with any third party claiming to be
        related to the Company on separate emails/phone calls, etc. We disclaim
        any liabilities towards any such incidents, which may arise due to your
        negligence or willful misconduct. Printlozy does not take any
        responsibility for the same.
        <br />
        <br />
      </p>
    </div>
  );
};

export default PrivacyPolicy;
