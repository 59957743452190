const end_point = {
  LOGIN: `mobileLogin`,
  LOGIN_VERIFY: `loginVerify`,
  LOGOUT: `logout`,
  BANNER: `banner`,
  GET_CATEGORY: `getCategory`,
  GET_PRODUCT: `getProduct`,
  GET_PRODUCT_DETAIL: `getProductId`,
  NEW_ARRIVAL: `new-arrival`,
  ADD_CART: `addCart`,
  GET_CART: `getCart`,
  REMOVE_CART: `removeCart`,
  CATEGORY_WISE_PRODUCT: `getProductByCategory`,
  ADD_CUSTOMER_ADDRESS: `addCustomerAddress`,
  GET_CUSTOMER_ADDRESS: `getCustomerAddresses`,
  UPDATE_CUSTOMER_ADDRESS: `updateCustomerAddress`,
  DELETE_CUSTOMER_ADDRESS: `deleteCustomerAddress`,
  GET_COUPON: `getCoupon`,
  APPLY_COUPON: `ApplyCoupon`,
  CHECKOUT: `checkout`,
  UPDATE_PROFILE: `profileupdate`,
  GET_PROFILE: `getprofile`,
  GET_ORDER: `getOrder`,
  ORDER_DETAIL: `invoice`,
  TRACK_ORDER: `track_order`,
  SEARCH: `getsearchProduct`,
};

export default end_point;
