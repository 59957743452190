import React, { useState, useEffect } from "react";
import ActivityIndicator from "./ActivityIndicator";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { URL, ImgURL, rupees_icon } from "../url/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import product from "../asset/printlozy/product.png";

const ProductCategories = ({ first_catg_products, loading, isLoggedIn }) => {
  const dispatch = useDispatch();
  const [dataVariant, setDataVariant] = useState("");
  const [Token, setToken] = useState("");
  useEffect(() => {
    checkLogin();
  }, []);
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const updateCart = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(id));
    formdata.append("qty", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}updateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          getCart();
          showToast(newData.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };
  //  only cart Update
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        {
          newData.data.cart.map((item) => {
            dispatch(refreshCart(item));
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleImageError = (event) => {
    event.target.src = product; // Use the logo variable from your imports
  };

  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container-fluid newarrival">
      <div className="row">
        {first_catg_products?.map((item) => {
          // let calculate =
          //   (item.productvariants.p_price / item.productvariants.p_mrp) * 100;
          // let Percentage = 100 - calculate;
          return (
            <div key={item.id} className="col-6 col-md-4 col-lg-2 my-2 mx-0">
              <Link
                className="card newarrivalproduct"
                to={`/product/${item.id}`}
              >
                <div className="newarrivalimage">
                  <img
                    src={`${ImgURL}product_image/${item.p_image[0]}`}
                    alt={`${item.p_name}`}
                    onError={handleImageError}
                    className="w-100"
                  />
                </div>
                <div className="newarrivaltext">
                  <p>{item.p_name}</p>
                  <div className="new_arrival_add_text">
                    <h6>
                      <span
                        className="text-muted"
                        style={{
                          textDecoration: "line-through",
                          marginRight: "5px",
                        }}
                      >
                        {rupees_icon}
                        {item.productvariants.p_mrp}
                      </span>
                      <span className="text-success fw-bold">
                        {rupees_icon}
                        {item.productvariants.p_price}
                      </span>
                    </h6>
                    <div role="button" className="icon d-none">
                      <i className="fas fa-info-circle"></i>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
        <div
          className="modal fade"
          id="variantsModal"
          tabIndex="-1"
          aria-labelledby="variantsModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="variantsModalLabel">
                  {dataVariant.name}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {dataVariant ? (
                  <>
                    {dataVariant.variants.map((value) => {
                      return (
                        <div
                          className="container d-flex"
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: 6,
                          }}
                        >
                          {value.mrp === value.sp ? (
                            <>
                              <div>{value.size}</div>
                              <div className="d-flex">₹{value.sp}</div>
                              {!isLoggedIn ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-success w-100"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#variantsModal"
                                  onClick={() => {
                                    updateCart(value.id);
                                  }}
                                  data-bs-dismiss="modal"
                                >
                                  Add
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-success w-100"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  // onClick={() => {
                                  //   console.log("e:::::::", value);
                                  // }}
                                >
                                  Add
                                </button>
                              )}
                            </>
                          ) : (
                            <>
                              <div>{value.size}</div>
                              <div className="d-flex">
                                <del style={{ marginRight: 4 }}>
                                  ₹{value.mrp}
                                </del>
                                ₹{value.sp}
                              </div>
                              {!isLoggedIn ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-success w-100"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#variantsModal"
                                  onClick={() => {
                                    updateCart(value.id);
                                  }}
                                  data-bs-dismiss="modal"
                                >
                                  Add
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-success w-100"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Add
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategories;
