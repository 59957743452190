import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Orders from "../MyOrders/order";
import NewAddress from "../MyAddress/newAddress";
import Wallet from "../MyWallet/wallet";
import ProfileList from "../component/profileList";
import ProfileDetails from "../component/ProfileDetails";
import { Helmet } from "react-helmet";
const Profile = () => {
  const [selectTab, setSelectTab] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSelectSubcategory = (subcategoryId) => {
    setSelectTab(subcategoryId);
  };
  return (
    <div className="container-fluid mt-5">
      {/* <Helmet>
        <title>Profile</title>
      </Helmet>*/}
      {/* <div className="row"> */}
      <div className="row py-3 horizontalContainer">
        <div className="col-md-3 my-4">
          <ProfileList onSelectSubcategory={handleSelectSubcategory} />
        </div>
        <div className="col-md-9 my-4">
          {
            selectTab == 0 ? (
              <ProfileDetails />
            ) : selectTab == 1 ? (
              <NewAddress />
            ) : (
              <Orders />
            )
            //  : (
            //   <Wallet />
            // )
          }
        </div>
      </div>
    </div>
  );
};

export default Profile;
