import React from "react";
import Cookies from "js-cookie";
import { URL } from "../url/api";
import { toast } from "react-toastify";
import end_point from "../endpoints/apiendpoints";
const ProfileList = ({ onSelectSubcategory }) => {
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        logoutApp(token);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const logoutApp = (Token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("os", "WEB");

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${URL}${end_point.LOGOUT}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.status == "Success") {
          window.location.replace("/login");
          Cookies.remove("user_session");
        }
        showToast(newData.msg);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div className="profilelist">
      <div className="col card" onClick={() => onSelectSubcategory("0")}>
        <i className="fa-solid fa-user iconClass"></i>
        <span className="card-text">Profile</span>
      </div>
      <div className="col card" onClick={() => onSelectSubcategory("1")}>
        <i className="fa fa-map-location-dot iconClass"></i>
        <span className="card-text">My Address</span>
      </div>
      <div className="col card" onClick={() => onSelectSubcategory()}>
        <i className="fa-solid fa-wallet iconClass"></i>
        <span className="card-text">My Orders</span>
      </div>
      <div className="col card" onClick={() => checkLogin()}>
        <i className="fa-solid fa-right-from-bracket iconClass"></i>
        <span className="card-text">Logout</span>
      </div>
    </div>
  );
};

export default ProfileList;
