import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { URL, ImgURL } from "../url/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItemToCart,
  reduceItemToCart,
  addProducts,
  setCart,
} from "../reduxSetup/slices/productsSlice";
import almond from "../asset/pngImage/almond.png";
import end_point from "../endpoints/apiendpoints";

const Cart = () => {
  const data = useSelector((state) => state.product.data);
  const dispatch = useDispatch();
  const min_order = "500";
  // const [data, setData] = useState([]);
  const [countData, setCountData] = useState("");
  const [Token, setToken] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [addressSet, setAddressSet] = useState(false);
  const [add_Address, setAdd_Address] = useState(false);
  // const [update_Address, setUpdate_Address] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [Address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [alternateno, setAlternateno] = useState("");
  const [landmark, setLandmark] = useState("");
  const [email, setEmail] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [addressId, setAddressID] = useState("");
  useEffect(() => {
    checkLogin();
    Total_price();
    // getCart();
  }, [data]);

  useEffect(() => {
    loadCartFromLocalStorage();
  }, []);
  const Total_price = () => {
    let arr = [];
    if (data && data.length) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const qty_product = element?.qty;
        const SP_price = element?.variant[0]?.p_price;
        const price_SP = SP_price * qty_product;
        arr.push(price_SP);
        const sum_SP = eval(arr.join("+"));
        setCountData(sum_SP);
      }
    }
  };
  const indianStates = [
    { key: "andamanAndNicobarIslands", value: "Andaman and Nicobar Islands" },
    { key: "andhraPradesh", value: "Andhra Pradesh" },
    { key: "arunachalPradesh", value: "Arunachal Pradesh" },
    { key: "assam", value: "Assam" },
    { key: "bihar", value: "Bihar" },
    { key: "chandigarh", value: "Chandigarh" },
    { key: "chhattisgarh", value: "Chhattisgarh" },
    { key: "delhi", value: "Delhi" },
    { key: "goa", value: "Goa" },
    { key: "gujarat", value: "Gujarat" },
    { key: "haryana", value: "Haryana" },
    { key: "himachalPradesh", value: "Himachal Pradesh" },
    { key: "jharkhand", value: "Jharkhand" },
    { key: "karnataka", value: "Karnataka" },
    { key: "kerala", value: "Kerala" },
    { key: "lakshadweep", value: "Lakshadweep" },
    { key: "madhyaPradesh", value: "Madhya Pradesh" },
    { key: "maharashtra", value: "Maharashtra" },
    { key: "manipur", value: "Manipur" },
    { key: "meghalaya", value: "Meghalaya" },
    { key: "mizoram", value: "Mizoram" },
    { key: "nagaland", value: "Nagaland" },
    { key: "odisha", value: "Odisha" },
    { key: "punjab", value: "Punjab" },
    { key: "rajasthan", value: "Rajasthan" },
    { key: "sikkim", value: "Sikkim" },
    { key: "tamilNadu", value: "Tamil Nadu" },
    { key: "telangana", value: "Telangana" },
    { key: "tripura", value: "Tripura" },
    { key: "uttarPradesh", value: "Uttar Pradesh" },
    { key: "uttarakhand", value: "Uttarakhand" },
    { key: "westBengal", value: "West Bengal" },
    { key: "puducherry", value: "Puducherry" },
  ];
  //show toast
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  //use all input field handle
  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const phoneInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 10) {
      setPhoneNumber(inputPhoneNumber);
    }
  };
  const emailInputChange = (e) => {
    setEmail(e.target.value);
  };
  const landmarkInputChange = (e) => {
    const inputLandmark = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputLandmark.length <= 10) {
      setLandmark(inputLandmark);
    }
  };
  const alterateInputChange = (e) => {
    const inputAlternateNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputAlternateNumber.length <= 10) {
      setAlternateno(inputAlternateNumber);
    }
  };
  const AddressInputChange = (e) => {
    setAddress(e.target.value);
  };
  const cityInputChange = (e) => {
    setCity(e.target.value);
  };
  const zipInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 6) {
      setZip(inputPhoneNumber);
    }
  };
  const stateInputChange = (e) => {
    setState(e.target.value);
  };
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CART}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const Increment = (item) => {
    const updatedQty = parseFloat(item.qty) + 1;
    const updatedItem = { ...item, qty: updatedQty };
    console.log("updated item", updatedItem);
    addCart(updatedItem);
    dispatch(addProducts(updatedItem));
  };
  const addCart = (cartItems) => {
    console.log("value", cartItems);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(cartItems.variant[0].id));
    formdata.append("product_id", JSON.stringify(cartItems.product_id));
    formdata.append("id", cartItems.id);
    formdata.append("qty", cartItems.qty);
    formdata.append("color", cartItems.variant[0]?.p_color?.[0] || "");
    formdata.append("size", cartItems.variant[0]?.p_size?.[0] || "");
    formdata.append("image", cartItems.product[0].p_image[0]);
    formdata.append("description", cartItems.product[0].p_desc);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}${end_point.ADD_CART}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.success === true) {
          getCart();
          showToast("Cart updated successfully");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const Decrement = (item) => {
    if (item.qty > 1) {
      const updatedQty = parseFloat(item.qty) - 1;
      const updatedItem = { ...item, qty: updatedQty };
      addCart(updatedItem);
      dispatch(reduceItemToCart(updatedItem));
    } else {
      removecart(item);
      dispatch(removeItemToCart(item.id));
    }
  };

  const loadCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem("cart");

    if (savedCart) {
      dispatch(setCart(JSON.parse(savedCart)));
    }
  };
  const removecart = (item) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var formdata = new FormData();
    formdata.append("cart_id", item.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.REMOVE_CART}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        showToast(userData.msg);
        getCart();
      })
      .catch((error) => console.log("error", error));
  };

  // Get user Address
  const getCustomerAddresses = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setAddressData(userData.data);
        setAddressID(userData.data[0].id);
        Cookies.set("user_address", JSON.stringify(userData.data[0]));
      })
      .catch((error) => console.log("error", error));
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };
  // Add  New Customer Address
  const addCustomerAddress = () => {
    if (!name) {
      showToastErr("Contact person name is required");
      return;
    }
    if (!email) {
      showToastErr("Email is required");
      return;
    }
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      showToastErr("Enter a valid 10-digit mobile number");
      return;
    }
    if (!Address) {
      showToastErr("Address is required");
      return;
    }
    if (alternateno && !/^\d{10}$/.test(alternateno)) {
      showToastErr("Enter a valid 10-digit alternate number");
      return;
    }
    if (!city) {
      showToastErr("City is required");
      return;
    }
    if (!zip || !/^\d{6}$/.test(zip)) {
      showToastErr("Enter a valid 6-digit pincode");
      return;
    }
    if (!state) {
      showToastErr("State is required");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("address", Address);
    formdata.append("email", email);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", zip);
    formdata.append("contact_person", name);
    formdata.append("mobile_no", phoneNumber);
    formdata.append("landmark", landmark);
    formdata.append("alternate_no", alternateno);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.ADD_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.success === true) {
          getCustomerAddresses();
          resetform();
          setAdd_Address(false);
          setAddressSet(true);
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const resetform = () => {
    setName("");
    setPhoneNumber("");
    setAddress("");
    setCity("");
    setEmail("");
    setState("");
    setZip("");
    setLandmark("");
    setAlternateno("");
  };

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasWithBackdrop"
      aria-labelledby="offcanvasWithBackdropLabel"
    >
      {add_Address === false ? (
        <>
          {addressSet === false ? (
            <>
              {/* this is cart code */}
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">
                  My Cart
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="offcanvas-body"
                style={{
                  marginBottom: 50,
                }}
              >
                {data?.map((item, index) => {
                  return (
                    <div
                      className="card px-1"
                      key={item.id}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 5,
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-4 " style={{ width: "30%" }}>
                        <img
                          key={item.id}
                          alt={item.product[0].p_name}
                          loading="lazy"
                          onError={handleImageError}
                          src={`${ImgURL}product_image/${item.product[0].p_image[0]}`}
                          className="card-img-top mx-auto"
                          style={{
                            height: "80px",
                            resizeMode: "center",
                            width: "80px",
                            margin: 2,
                          }}
                        />
                      </div>
                      <div className="col-md-4" style={{ width: "45%" }}>
                        <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                          {item.product[0].p_name}
                          <br />
                          {item.variant[0].p_size}
                        </p>
                        {item.variant.mrp !== item.variant.sp ? (
                          <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                            <del>₹{item.variant[0].p_mrp}</del>₹
                            {item.variant[0].p_price}
                          </p>
                        ) : (
                          <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                            ₹{item.variant[0].p_price}
                          </p>
                        )}
                      </div>
                      <div
                        className="col-md-4 btn-group"
                        role="group"
                        aria-label="Basic example"
                        style={{ width: "25%" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (item.qty > 1) {
                              Decrement(item);
                              // dispatch(reduceItemToCart(item));
                            } else {
                              Decrement(item);
                              // dispatch(removeItemToCart(item.id));
                            }
                          }}
                        >
                          -
                        </button>
                        <button className="btn btn-primary p-0">
                          {item.qty}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            Increment(item);
                            // dispatch(addProducts(item));
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              {data && data.length > 0 ? (
                <div
                  className="card p-2 mx-1 proceedbutton"
                  type="button"
                  onClick={() => {
                    setAddressSet(true);
                    getCustomerAddresses();
                  }}
                >
                  <div className="row">
                    <div className="col-5">
                      <p className="pTag text-white">
                        {"Total"} ₹ {countData}
                      </p>
                    </div>
                    <div className="col-7">
                      <p className="pTag text-white">{" Proceed To Pay"}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {/* this is My Address code */}
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                  My Address
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setAddressSet(false);
                    resetform();
                  }}
                ></button>
              </div>
              <button
                type="button"
                className="btn btn-primary mx-1"
                onClick={() => {
                  setAdd_Address(true);
                  setAddressSet(false);
                  resetform();
                }}
              >
                + Add Address
              </button>
              <div className="offcanvas-body p-2">
                {addressData.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={
                        item.id === addressId
                          ? "card addressSelect"
                          : "card address"
                      }
                      onClick={() => {
                        setAddressID(item.id);
                        Cookies.set("user_address", JSON.stringify(item));
                      }}
                    >
                      {/* <div className="col-md-12 p-1"> */}
                      <p className="pTag p-1" style={{ fontSize: 16 }}>
                        {item.contact_person}
                        <br />
                        {item.mobile_no}
                        <br />
                        {item?.address &&
                          item?.city &&
                          item?.pincode &&
                          item?.landmark &&
                          item?.state && (
                            <span>
                              {item.city},{item.address},{item.landmark},
                              {item.pincode},{item.state}
                            </span>
                          )}
                      </p>
                    </div>
                  );
                })}
              </div>
              <Link
                className="my-nav-link"
                to={{
                  pathname: `/checkout/${addressId}`,
                  state: { totalPrice: countData },
                }}
              >
                <div
                  className="card p-2 mx-1 proceedbutton text-center"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddressSet(false);
                  }}
                >
                  <p className="pTag" style={{ color: "white" }}>
                    {"Done"}
                  </p>
                </div>
              </Link>
            </>
          )}
        </>
      ) : (
        <>
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Add Address
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setAdd_Address(false);
                setAddressSet(true);
              }}
            ></button>
          </div>
          <div
            className="offcanvas-body"
            style={{
              marginBottom: 50,
            }}
          >
            <div className="row">
              <div className="col-12 my-2">
                <input
                  type="text"
                  defaultValue={name ? name : ""}
                  className="form-control"
                  placeholder="First name"
                  aria-label="First name"
                  id="userName"
                  required
                  onChange={nameInputChange}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  defaultValue={email ? email : ""}
                  className="form-control"
                  placeholder="Enter Email"
                  aria-label="Enter Email"
                  id="email"
                  required
                  onChange={emailInputChange}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="number"
                  className="form-control"
                  defaultValue={phoneNumber ? phoneNumber : ""}
                  placeholder="Phone Number"
                  aria-label="Phone number"
                  required
                  onChange={phoneInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 10) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="number"
                  className="form-control"
                  defaultValue={alternateno ? alternateno : ""}
                  placeholder="Alternate Number"
                  aria-label="Alternate number"
                  required
                  onChange={alterateInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 10) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={Address ? Address : ""}
                  placeholder="Address"
                  aria-label="Address"
                  required
                  onChange={AddressInputChange}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={city ? city : ""}
                  placeholder="City"
                  aria-label="City"
                  required
                  onChange={cityInputChange}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={landmark ? landmark : ""}
                  placeholder="Landmark"
                  aria-label="Landmark"
                  required
                  onChange={landmarkInputChange}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={zip ? zip : ""}
                  placeholder="Zip"
                  aria-label="Zip"
                  required
                  onChange={zipInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 6) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <select
                  className="form-control"
                  value={state || ""}
                  onChange={stateInputChange}
                  aria-label="State"
                  required
                >
                  <option value="" disabled>
                    Select State
                  </option>
                  {indianStates.map((stateItem) => (
                    <option key={stateItem.key} value={stateItem.value}>
                      {stateItem.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div
            className="card p-2  mx-1"
            type="button"
            style={{
              width: "98%",
              marginTop: 4,
              textAlign: "center",
              background: `linear-gradient(#47bd67, #1d4e48)`,
              marginBottom: 10,
            }}
            onClick={() => {
              addCustomerAddress();
            }}
          >
            <p className="fw-bold pTag" style={{ color: "white" }}>
              {"Add"}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
