import Home from "../page/Home";
import Cart from "../page/Cart";
// import Products from "./page/Products";
import SingelProducts from "../page/SingelProducts";
import Shop from "../page/Shop";
import SubCategories from "../component/SubCategories";
import Login from "../page/Login";
import Search from "../page/Search";
import About from "../ourPolicy/About";
import RedirectOldProductUrl from "../component/RedirectOldProductUrl";
import HoleSale from "../page/HoleSale";
import Contact from "../ourPolicy/Contact";
import TermCondition from "../ourPolicy/Term&Condition";
import ShippingPolicy from "../ourPolicy/ShippingPolicy";
import PrivacyPolicy from "../ourPolicy/PrivacyPolicy";
import Checkout from "../page/Checkout";
import Profile from "../page/Profile";
import Order from "../MyOrders/order";
import Address from "../MyAddress/newAddress";
import OrderPlaced from "../page/OrderPlaced";
import PageNotFound from "../page/PageNotFound";

const route = [
  { path: "/", component: Home },
  { path: "/grocery/:id/:itemName", component: Shop },
  { path: "/product/:id", component: SingelProducts },
  // { path: "/category/:id/:itemName", component: SubCategories },
  {
    path: "/category/:id/:itemName",
    component: SubCategories,
  },
  { path: "/cart", component: Cart },
  { path: "/login", component: Login },
  { path: "/search", component: Search },
  { path: "/about", component: About },
  { path: "/contact", component: Contact },
  { path: "/term&Condition", component: TermCondition },
  { path: "/shipping&refundPolicy", component: ShippingPolicy },
  { path: "/Privacy-Policy", component: PrivacyPolicy },
  { path: "/checkout/:id", component: Checkout },
  // { path: "/checkout", component: Checkout },
  { path: "/profile", component: Profile },
  { path: "/orders", component: Order },
  { path: "/OrderPlaced/:id", component: OrderPlaced },
  { path: "/address", component: Address },
  { path: "/Khari-Baoli-Dry-Fruits-Rate-List-Today", component: HoleSale },
  { path: "/product-details.php", component: RedirectOldProductUrl },
  { path: "*", component: PageNotFound },
];

export default route;
