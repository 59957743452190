import React, { useState, useEffect } from "react";
import logo from "../asset/printlozy/logo.png";
import logo1 from "../asset/printlozy/logo_img.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Cart from "../page/Cart";
import { useSelector, useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import { URL } from "../url/api";
import end_point from "../endpoints/apiendpoints";
import appstore from "../asset/printlozy/app store.png";
import playstore from "../asset/printlozy/play store.png";

export default function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cookieValue = Cookies.get("user_session");
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("q");
  const [searchTerm, setSearchTerm] = useState("");
  const count = useSelector((state) => state.product.data);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  useEffect(() => {
    checkLogin();
    return () => {
      checkLogin();
    };
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        getCartData(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const getCartData = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CART}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          Cookies.remove("user_session");
          Cookies.remove("user_address");
        } else if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((result) => {
        const newData = JSON.parse(result);

        newData.cart.map((item) => {
          dispatch(refreshCart(item));
        });
        if (newData?.cart?.length === "0") {
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };
  const handleSearch = () => {
    // Navigate to the search results page with the search term as a query parameter
    navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container-fluid">
          {/* Logo */}
          <a
            className="logoheader navbar-brand d-flex align-items-center"
            href="/"
          >
            <img src={logo} alt="Printlozy" loading="lazy" />
          </a>
          <div className="search_store_icon">
            {/* Search Bar */}
            <div className="header_search">
              {search ? null : (
                <div className="input-group">
                  {/* <div className="input-group-text">@</div> */}
                  <input
                    type="search"
                    placeholder="Search"
                    className="form-control"
                    id="autoSizingInputGroup"
                    value={searchTerm}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleSearch();
                      setNavbarCollapsed(true);
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              )}
            </div>
            {/* App Store Links */}
            <div className="storeicon">
              <a href="" className="me-2">
                <img src={playstore} alt="Get it on Google Play" />
              </a>
              <a href="" className="me-3">
                <img src={appstore} alt="Download on the App Store" />
              </a>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {/* loginbutton or profile icon */}
            <div style={{ alignItems: "center", alignSelf: "center" }}>
              {cookieValue == null ? (
                <Link
                  className="nav-link "
                  to="/login"
                  onClick={() => setNavbarCollapsed(true)}
                >
                  <button type="button" className="m-2 registerbutton">
                    login/Register
                  </button>
                  <div
                    className="text-center loginbutton"
                    style={{ lineHeight: 0.2 }}
                  >
                    <i className="fa fa-right-to-bracket my-icon p-0" />
                    <br />
                    <span style={{ fontSize: 12, marginTop: 0 }}>login</span>
                  </div>
                </Link>
              ) : (
                <Link
                  className="nav-link "
                  to="/profile"
                  onClick={() => setNavbarCollapsed(true)}
                >
                  <i className="fa fa-user-circle my-icon profileicon" />
                  {/* <i className="fa fa-user-circle my-icon profileicon1" /> */}
                </Link>
              )}
            </div>
            {/* cart icon */}
            <div
              className="cartdiv d-flex"
              style={{
                alignItems: "center",
              }}
            >
              <i
                className="fa-solid fa-cart-shopping my-icon"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
                role="button"
              />
              {count?.length > 0 ? (
                <p className="cart">{count?.length}</p>
              ) : null}
            </div>
          </div>
        </div>
      </nav>
      <Cart />
    </div>
  );
}
