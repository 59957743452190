import React, { useState, useEffect } from "react";
import { URL, ImgURL, rupees_icon } from "../url/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { Link, useLocation } from "react-router-dom";
import ActivityIndicator from "./ActivityIndicator";
import product from "../asset/printlozy/product.png";
import end_point from "../endpoints/apiendpoints";

const ImageSlider = ({ images }) => {
  const { pathname } = useLocation();
  const [bannerData, setBannerData] = useState([]);
  const [data, setData] = useState([]);
  const [ProductData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [Show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Make both API calls concurrently
        const [
          dashboardResponse,
          CategoriesResponse,
          Productresponse,
          NewArrivalresponse,
        ] = await Promise.all([
          fetch(`${URL}${end_point.BANNER}`, {
            method: "GET",
            redirect: "follow",
          }),
          fetch(`${URL}${end_point.GET_CATEGORY}`, {
            method: "GET",
            redirect: "follow",
          }),
          fetch(`${URL}${end_point.GET_PRODUCT}`, {
            method: "GET",
            redirect: "follow",
          }),
        ]);

        // Process the response from the first API call (banner)
        const dashboardResult = await dashboardResponse.text();
        const dashboardData = JSON.parse(dashboardResult);
        setBannerData(dashboardData.data);
        // Process the response from the second API call (getCategory)
        const CategoriesResult = await CategoriesResponse.text();
        const CategoriesData = JSON.parse(CategoriesResult);
        setData(CategoriesData.data.data);
        // Process the response from the second API call (getProduct)
        const ProductResult = await Productresponse.text();
        const ProductData = JSON.parse(ProductResult);
        setProductData(ProductData.data.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageError = (event) => {
    event.target.src = product;
  };
  const handleBannerClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  };
  const displayedProducts = Show ? ProductData : ProductData.slice(0, 6);
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container-fluid">
      {/* This is banner code */}
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="2000"
      >
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <div className="carousel-inner">
          {bannerData?.map((item, index) => {
            return (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
                // onClick={() => handleBannerClick(item?.redirect_url)}
                // style={{
                //   cursor: item.redirect_url ? "pointer" : "default",
                // }}
              >
                <img
                  src={`${ImgURL}images/slider/${item?.image}`}
                  alt={`Banner ${index + 1}`}
                  loading="lazy"
                  className="d-block w-100 bannerheight"
                />
              </div>
            );
          })}
        </div>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <h1
        className="textColor"
        style={{
          fontSize: 20,
          margin: 12,
          // marginBottom: 0,
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        Categories
      </h1>

      {/* This is Categories code  */}
      <div className="slider-button">
        <button className="swiper-button-next" onClick={handlePrev}>
          <i className="fa-solid fa-angles-left"></i>
        </button>{" "}
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {data.map((item) => {
            let name = item.name;
            const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");

            return (
              <SwiperSlide key={item.id}>
                <Link
                  className="nav-link"
                  aria-current="page"
                  to={`/category/${item.id}/${product}`}
                >
                  <img
                    key={item.id}
                    loading="lazy"
                    className="image-hover"
                    src={`${ImgURL}${item.image}`}
                    alt={item.name + "Image"}
                    onError={handleImageError}
                    style={{
                      height: 170,
                      width: "100%",
                      margin: "0%",
                      resizeMode: "contain",
                      borderRadius: 8,
                      padding: 1,
                    }}
                  />
                  <div style={{ textAlign: "center", margin: 8 }}>
                    <h6>{item.name}</h6>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button className="swiper-button-prev" onClick={handleNext}>
          <i className="fa-solid fa-angles-right"></i>
        </button>
      </div>

      {/* This is product code */}
      <div className="container newarrival">
        <div className="col-12" style={{ textAlign: "center", margin: 10 }}>
          <h3 className="textColor">Recommended Products</h3>
        </div>
        <div className="row" style={{ justifyContent: "center" }}>
          {displayedProducts.map((item, index) => {
            // let name = subCategory.name;
            // const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
            // let subCategoryName = subCategory?.category?.name;
            return (
              <div key={index} className="col-6 col-md-4 col-lg-2 my-2 mx-0">
                <Link
                  to={`/product/${item.product_id}`}
                  className="card newarrivalproduct"
                >
                  <div className="newarrivalimage">
                    <img
                      src={`${ImgURL}product_image/${item.p_image[0]}`}
                      alt={`${item.p_name}`}
                      onError={handleImageError}
                      className="w-100"
                    />
                  </div>
                  <div className="newarrivaltext">
                    <p>{item.p_name}</p>
                    <div className="new_arrival_add_text">
                      <h6>
                        <span
                          className="text-muted"
                          style={{
                            textDecoration: "line-through",
                            marginRight: "5px",
                          }}
                        >
                          {rupees_icon}
                          {item.p_mrp}
                        </span>
                        <span className="text-success fw-bold">
                          {rupees_icon}
                          {item.p_price}
                        </span>
                      </h6>
                      <div role="button" className="icon d-none">
                        <i className="fas fa-info-circle"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="row">
          {!Show ? (
            <button
              className="btn btn-outline-success"
              onClick={() => setShow(true)}
            >
              View All
            </button>
          ) : (
            <button
              className="btn btn-outline-success"
              onClick={() => setShow(false)}
            >
              Show Less
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
