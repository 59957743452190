import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Helmet } from "react-helmet";
import { ImgURL } from "../url/api";

export default function HoleSale() {
  const currentPageUrl = window.location.href;
  const externalPdfUrl = "https://admin.zayadryfruits.com/catalogue.pdf";
  const docs = [
    {
      uri: require("../asset/pdf/ZayaHoleShale.pdf"),
      fileType: "pdf",
      fileName: "Wholesale Price",
    },
  ];
  const downloadPDF = () => {
    // Replace 'your-pdf-url.pdf' with the actual URL of your PDF file
    window.open(externalPdfUrl, "_blank");
  };

  return (
    <div className="Container" style={{ marginTop: 85 }}>
      {/* <Helmet>
        <title>
          {"Khari Baoli Dry Fruits Rate List Today: Your One-Stop Guide"}
        </title>
        <meta
          name="Description"
          content={
            "Check today's Khari Baoli Dry Fruits Rates, Your easy guide for quality and prices. One place for all your dry fruit needs. Stay updated and make informed choices."
          }
        />
        <meta
          name="KeyWords"
          content="khari baoli dry fruits rate list today pdf, khari baoli dry fruits rate list today, khari baoli price list, khari baoli dry fruits rate list, khari baoli dry fruits price list, khari baoli dry fruits rate list today pdf download, dry fruits price in delhi, dry fruits rate in delhi, dry fruits rate in khari baoli delhi, dry fruit rate in delhi"
        />
        <meta
          property="og:title"
          content={
            "Khari Baoli Dry Fruits Rate List Today: Your One-Stop Guide "
          }
        />
        <meta
          property="og:description"
          content={
            "Check today's Khari Baoli Dry Fruits Rates, Your easy guide for quality and prices. One place for all your dry fruit needs. Stay updated and make informed choices."
          }
        />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dry Fruits" />
        <meta
          property="og:image"
          content="http://test.zayadryfruits.com/admin/public/images/sliders/1692253370.jpeg"
        />
      </Helmet>*/}
      <h1
        className="textColor"
        style={{
          fontSize: 26,
          marginVertical: 10,
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        Khari Baoli Dry Fruits Rate List Today
      </h1>
      <p style={{ textAlign: "center", margin: 20 }}>
        Check out today's Khari Baoli Dry Fruits Rate List, your go-to guide for
        prices and quality. It's your one-stop shop for info on Zaya Dry Fruits
        and more. Whether you're looking for top-notch quality or a
        budget-friendly option, this guide has you covered. Compare rates and
        make informed decisions for a satisfying dry fruit shopping experience.
        Zaya Dry Fruits, a trusted brand, is featured along with other reliable
        options. Stay in the loop and enjoy hassle-free shopping with this
        helpful resource.
      </p>
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={{ header: { disableHeader: true } }}
        theme={{
          primary: "#5296d8",
          secondary: "#ffffff",
          tertiary: "#5296d899",
          textPrimary: "#ffffff",
          textSecondary: "#5296d8",
          textTertiary: "#00000099",
          disableThemeScrollbar: true,
        }}
        className="pdf"
      />
      <div style={{ textAlign: "center", marginBottom: 20 }}>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={() => {
            downloadPDF();
          }}
        >
          Download PDF
        </button>
      </div>
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="p-3">
            <h2
              className="textColor"
              style={{
                textAlign: "center",
                marginLeft: 10,
              }}
            >
              Zaya Dry Fruits - Where Delicious Treats Meet Excellent Quality
            </h2>
            <br />
            <p style={{ textAlign: "justify", marginLeft: 10 }}>
              Step into the world of Zaya Dry Fruits, where snacking is anything
              but ordinary! We carefully select the finest treats from across
              the globe, creating a delightful mix of flavors from juicy raisins
              to exotic berries. Discover unique treasures like Iranian
              pistachios or Himalayan honey, and treat yourself or a loved one
              with our decadent gift boxes – perfect for any celebration.
            </p>
            <p style={{ textAlign: "justify", marginLeft: 10 }}>
              At Zaya, we're not just about taste; we're about celebrating your
              health. Our offerings are natural, preservative-free, and designed
              to nourish your body and soul with every delicious bite. Come
              visit us and experience the enchanting aroma and vibrant colors
              that will awaken your senses.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3">
            <img
              src={`${ImgURL}/footer_banner/banner1.jpeg`}
              alt="zayadryfruits"
              loading="lazy"
              style={{ height: 300, width: "85%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
