import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  const sectionRef = useRef(null);
  const currentPageUrl = window.location.href;
  // useEffect(() => {
  //   if (window.location.hash === "#shipping&refund-policy") {
  //     sectionRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);
  return (
    <div
      className="container shippingpolicy"
      style={{ marginTop: 65, textAlign: "justify" }}
      ref={sectionRef}
    >
      {/* <Helmet>
        <title>Shipping & Refund Policy</title>
        <meta property="og:title" content={"Shipping & Refund Policy"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>*/}
      <br />
      <h4 className="text-center mt-5">Shipping & Refund Policy</h4>
      {/* <p className="textColor">Last updated June 24, 2021</p> */}
      <hr />
      <h3>Cancellation Policy</h3>
      <br />
      <p>
        Printlozy reserves the right to cancel any order without any
        explanation. We ensure that communication regarding the cancellation of
        an order or refund will be done in a reasonable time.
        <br />
        <br /> 1. You can cancel the order from the My Order Section in Account
        before the order is dispatched.
        <br />
        <br /> 2. If the order is already shipped, we won't be able to cancel
        it. However, you can refuse to accept the shipment, and the amount will
        be refunded to your account. <br />
        <br />
        3. The full amount will be refunded to your account, including your
        shipping charges if any. If your query is not solved, you can email us
        all the details, or you can contact our customer service representative
        directly at +91 9988007944 between 10:30 am to 06:00 pm (Monday -
        Saturday).
        <br />
      </p>
      <h3> Returns, Exchange & Refund Policy</h3>
      <br />
      <p>
        Returns, Exchange & Refund Exchange We are trying our best to serve your
        order with the appropriate products and sizes. In case you receive the
        wrong product or it doesn't fit your size, you can exchange it within 7
        days of delivery.
        <br />
        <br /> Your pickup will be arranged by Printlozy within 2-3 working days
        of your request. All products must be unused, unwashed, and returned
        with the original packing & tags when we receive them. In case the order
        does not meet the above conditions, the exchange request will not be
        processed and your order will be sent back to your picked-up address.
        <br />
        <br /> Returns We are working hard to provide you with the latest
        fashion and home décor products at affordable rates with the best
        quality fabric and material. If you didn't like the product or it
        doesn't meet your expectations, simply return it within 7 days of
        delivery. We can arrange a pick-up for you with an easy online return
        process.
        <br /> <br />
        1. Login to your My Orders section at
        <a href="https://www.printlozy.com"> www.printlozy.com</a>
        <br />
        <br /> 2. Select the Order and click Return Product or To return the
        product or contact us Shipping Charges and COD charges charged at the
        time of placing the order are non-refundable. We shall charge you
        shipping charges of ₹100 in case of reverse pick-up; the same shall be
        deducted from your refund. <br />
        <br />
        <b> Important Points to Remember:</b>
        <br />
        <br /> • Return requests need to be within 7 days of the delivery date.
        <br />
        <br /> • Your Pickup will be done in 2-3 working days.
        <br />
        <br /> • All returned products must be unused, unwashed, or undamaged
        and returned with the original packing and tags when we receive them.
        Items without tags will not be accepted. The refund is subject to your
        returns meeting the above conditions, else returns will not be accepted.
        In case you receive a Damaged / Defective / Wrong / Missing product, we
        should be notified within 24 hours of delivery. We request you to email
        us a photograph of the Damaged / Defective / Wrong / Missing product. In
        case you fail to inform us about the same, the return might not be
        processed.
        <br />
        <br /> <b>Refund Policy Amount Refund:</b>
        <br />
        <br /> 1. If you return a product, the COD charges you paid when placing
        the order will be deducted from your refund.
        <br />
        <br /> 2. Additionally, a reverse shipping fee of ₹100 will be charged,
        and the remaining amount will be refunded to your original payment
        method.
        <br />
        <br /> 3. However, if you choose to receive your refund in your wallet,
        the full amount (except the COD charges) will be credited without
        deducting the ₹100 reverse shipping fee. <br />
        <br />
        <b>Mode of Refund:</b>
        <br />
        <br />
        <b> 1. Prepaid Orders:</b> The amount will be refunded to the same
        account from which the order has been placed. Once we receive the
        product, the amount will be processed in 1-2 working days. Your money
        will be reflected in your account in 5-7 working days.
        <br />
        <br /> <b>2. COD Orders:</b> The amount will be transferred to the Bank
        Account mentioned while placing the Return Request, and your amount will
        be reflected in your account within 7 working days of receiving the
        details.
        <br />
        <br /> <b>3. Wallet Method:</b> In case of wrong Bank Details,
        intimation will be sent for the same.
        <br />
      </p>
      <h3> Shipping Policy</h3>
      <br />
      <p>
        At Printlozy, we aim to deliver your orders quickly and efficiently by
        collaborating with the best courier partners.
        <br /> <br />
        <b>Standard Delivery:</b>
        <br />
        <br /> We process and dispatch orders within 24-48 hours.
        <br />
        <br />
        <b> Delivery times vary by location:</b>
        <br />
        <br />
        <table className="table table-bordered w-50">
          <thead>
            <tr>
              <th>Location</th>
              <th>Delivery Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Chandigarh</th>
              <td>2-3 working days</td>
            </tr>
            <tr>
              <th>Metro</th>
              <td>5-7 working days</td>
            </tr>
            <tr>
              <th>Other Region</th>
              <td>10 working days</td>
            </tr>
          </tbody>
        </table>
        <br /> <b>Shipping Charges:</b> <br /> Free shipping on all orders.
        <br /> <br />
        <b>COD Fee:</b> <br /> COD fee: Flat ₹100 per order. <br />
        <br />
        <b>Delivery Time:</b> <br />
        Delivery times are calculated in business days and exclude weekends and
        public holidays. <br /> External factors such as weather or logistical
        issues may cause delays. We appreciate your understanding in such cases.
        <br />
        <br /> For any order or delivery concerns, contact our customer support
        team. We're here to ensure you have a smooth and satisfying shopping
        experience with Printlozy. <br />
        <br />
        <b>Self-Shipping of Orders for Returns:</b>
        <br />
        <br /> If your pincode is non-serviceable for a reverse pickup, you will
        need to courier the product(s) to the following address: <br />
        <br />
        <i>
          Kalakar Technologies #791, <br />
          Near Dispensary, Hallo Majra - 160002
        </i>
        <br />
        <br />
        Please ensure the items are packed securely to prevent any loss or
        damage during transit. Include the ORDER ID and registered mobile number
        on the packaging. All items must be in unused condition, with original
        tags attached and packaging intact. Within 48 hours of receiving the
        product(s), the full product price will be refunded, and no reverse
        pickup charges of ₹100 will be deducted. <br />
        <br />
        <b>Please Note:</b>
        <br />
        <br /> We request that you do not use The Professional Couriers for
        self-return, as they are not reliable. Please ensure your courier costs
        do not exceed the amount stipulated above. We recommend using ‘Speed
        Post’ as your courier service, as it is a Government of India-owned
        entity with the most widely distributed postal network in India.
        <br />
        <br /> <b>Reasons for Failed Delivery:</b>
        <br />
        <br />
        Sometimes, delivery attempts might fail due to the following reasons:
        <br />
        <br /> • Incorrect or incomplete address details.
        <br />
        <br /> • The recipient is unavailable, or the phone number provided is
        incorrect.
        <br />
        <br /> • The delivery address is in a restricted area.
        <br />
        <br /> • Payment was not made during delivery for COD orders.
        <br /> <br />• The recipient has moved or shifted to a different
        location.
        <br />
        <br />
        <b> Please Note:</b> <br />
        <br />
        If delivery fails on three attempts, the order will be cancelled.
        <br />
        <br />
      </p>
    </div>
  );
};

export default ShippingPolicy;
