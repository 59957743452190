import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { URL } from "../url/api";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import end_point from "../endpoints/apiendpoints";

const NewAddress = () => {
  const [Token, setToken] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [addressId, setAddressID] = useState("");
  const [name, setName] = useState("");
  const [phone_No, setPhone_no] = useState("");
  const [address, setaddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [idData, setIdData] = useState("");
  const [deleteAddress, setDeleteAddress] = useState("");
  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
        getCustomerAddresses(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // Get All Customer Address    -------------
  const getCustomerAddresses = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.GET_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setAddressData(userData.data);
        setAddressID(userData.data[0].id);
      })
      .catch((error) => console.log("error", error));
  };

  // Delete Customer Address    -------------
  const deleteCustomerAddress = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("id", deleteAddress);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.DELETE_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        getCustomerAddresses(Token);
        showToast(userData.msg);
      })
      .catch((error) => console.log("error", error));
  };

  //Add Customer Address    ---------------
  const addCustomerAddress = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("address", address);
    formdata.append("city", city);
    formdata.append("landmark", landmark);
    formdata.append("state", state);
    formdata.append("pincode", pincode);
    formdata.append("contact_person", name);
    formdata.append("mobile_no", phone_No);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.ADD_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.success === true) {
          getCustomerAddresses(Token);
          resetform();
        }
        const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((error) => console.log("error", error));
  };

  // Update Customer Address    -------------
  const updateCustomerAddress = () => {
    if (
      !idData ||
      !address ||
      !city ||
      !state ||
      !pincode ||
      !name ||
      !phone_No
    ) {
      showToastErr("Please fill in all required fields.");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("address_id", idData);
    formdata.append("address", address);
    formdata.append("landmark", landmark);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", pincode);
    formdata.append("contact_person", name);
    formdata.append("mobile_no", phone_No);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.UPDATE_CUSTOMER_ADDRESS}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.success == true) {
          getCustomerAddresses(Token);
          const modal = document.getElementById("EditAddress");
          if (modal) {
            modal.classList.remove("show");
            modal.setAttribute("aria-hidden", "true");
            document.body.classList.remove("modal-open");
            const backdrop = document.querySelector(".modal-backdrop");
            if (backdrop) {
              backdrop.remove();
            }
          }
        }
        const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((error) => console.log("error", error));
  };

  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const phoneInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 10) {
      setPhone_no(inputPhoneNumber);
    }
  };
  const houseInputChange = (e) => {
    setaddress(e.target.value);
  };
  const nearAboutInputChange = (e) => {
    setLandmark(e.target.value);
  };
  const cityInputChange = (e) => {
    setCity(e.target.value);
  };
  const stateInputChange = (e) => {
    setState(e.target.value);
  };
  const zipInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 6) {
      setPincode(inputPhoneNumber);
    }
  };

  // Clean All Input Field  Add & Update api
  const resetform = (e) => {
    setIdData("");
    setName("");
    setPhone_no("");
    setaddress("");
    setLandmark("");
    setCity("");
    setState("");
    setPincode("");
  };

  // Fill All Input Field To Use Update  Customer Address
  const handle_edit_address = (e) => {
    setIdData(e.id);
    setName(e.contact_person || "");
    setPhone_no(e.mobile_no || "");
    setaddress(e.address || "");
    setLandmark(e.landmark || "");
    setCity(e.city || "");
    setState(e.state || "");
    setPincode(e.pincode || "");
  };

  return (
    <div className="container">
      {/* <Helmet>
        <title>Address</title>
      </Helmet>*/}
      <div
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        className="AddAddress my-2"
      >
        + Add Address
      </div>
      {addressData.map((item, index) => {
        return (
          <div
            key={index}
            className={
              item.id === addressId
                ? "card addressSelect p-2 d-flex justify-content-space-between"
                : "card address p-2 my-2 d-flex justify-content-space-between"
            }
            onClick={() => {
              setAddressID(item.id);
              Cookies.set("user_address", JSON.stringify(item));
            }}
          >
            <div className="row">
              <div className="col-md-9" style={{ textAlign: "left" }}>
                <p className="pTag">
                  {/* <i className="fa fa-user mx-1"></i> */}
                  {item.contact_person}
                  <br />
                  {/* <i className="fa fa-phone mx-1"></i> */}
                  {item.mobile_no}
                  <br />
                  {/* <i className="fa fa-map-location-dot mx-1"></i> */}
                  {item.address &&
                    item?.city &&
                    item?.landmark &&
                    item.pincode &&
                    item.state && (
                      <span>
                        {item.address}, {item.city},{item.landmark},{" "}
                        {item.state},{item.pincode}
                      </span>
                    )}
                </p>
              </div>
              <div className="col-md-3" style={{ textAlign: "right" }}>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#EditAddress"
                  className="btn btn-primary m-1"
                  onClick={() => {
                    handle_edit_address(item);
                  }}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary m-1"
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteAddress"
                  onClick={() => {
                    setDeleteAddress(item?.id);
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  resetform();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-label="First name"
                    required
                    value={name}
                    onChange={nameInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                    required
                    value={phone_No}
                    onChange={phoneInputChange}
                    onKeyPress={(event) => {
                      if (event.target.value.length >= 10) {
                        event.preventDefault(); // Prevent entering more characters
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row  my-4">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    aria-label="Address"
                    required
                    value={address}
                    onChange={houseInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Near About"
                    aria-label="Near About"
                    required
                    value={landmark}
                    onChange={nearAboutInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 my-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    aria-label="City"
                    required
                    value={city}
                    onChange={cityInputChange}
                  />
                </div>
                <div className="col-sm-6 my-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    aria-label="State"
                    required
                    value={state}
                    onChange={stateInputChange}
                  />
                </div>
              </div>
              <div className="col-sm-5 my-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Zip"
                  aria-label="Zip"
                  required
                  value={pincode}
                  onChange={zipInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 6) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  resetform();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  addCustomerAddress();
                }}
                data-bs-dismiss="modal"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="EditAddress"
        tabIndex="-1"
        aria-labelledby="EditAddressLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditAddressLabel">
                Edit Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  resetform();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-label="First name"
                    required
                    value={name}
                    onChange={nameInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Phone Number"
                    aria-label="Last name"
                    required
                    value={phone_No}
                    onChange={phoneInputChange}
                    onKeyPress={(event) => {
                      if (event.target.value.length >= 10) {
                        event.preventDefault(); // Prevent entering more characters
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row  my-4">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    aria-label="First name"
                    required
                    value={address}
                    onChange={houseInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Near About"
                    aria-label="Last name"
                    required
                    value={landmark}
                    onChange={nearAboutInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    aria-label="City"
                    required
                    value={city}
                    onChange={cityInputChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    aria-label="State"
                    required
                    value={state}
                    onChange={stateInputChange}
                  />
                </div>
              </div>
              <div className="col-sm-5 my-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Zip"
                  aria-label="Zip"
                  required
                  value={pincode}
                  onChange={zipInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 6) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  resetform();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  updateCustomerAddress();
                }}
                // data-bs-dismiss="modal"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="DeleteAddress"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure ? You want delete address !
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCustomerAddress();
                }}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAddress;
