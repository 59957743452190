import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { URL, ImgURL, rupees_icon } from "../url/api";
import ActivityIndicator from "../component/ActivityIndicator";
import product from "../asset/printlozy/product.png";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import end_point from "../endpoints/apiendpoints";
function OrderPlaced() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [newData, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Token, setToken] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  useEffect(() => {
    checkLogin();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(JSON.parse(session));
        getCartData(JSON.parse(session));
        getOrderDetails(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const getCartData = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}getCart`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          Cookies.remove("user_session");
          Cookies.remove("user_address");
        } else if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((result) => {
        const newData = JSON.parse(result);
        newData.cart.map((item) => {
          dispatch(refreshCart(item));
        });
        if (newData?.cart?.length === "0") {
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleImageError = (event) => {
    event.target.src = product;
  };
  const getOrderDetails = (session) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}${end_point.ORDER_DETAIL}/${id} `, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setNewData(newData.data);
        setCurrentStatus(newData.data[0].status);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const steps = [
    {
      label: "Pending",
      status: "pending",
      description: (currentStatus) =>
        ["pending", "order_confirm", "shipped", "delivered"].includes(
          currentStatus
        )
          ? "Your order has been placed."
          : "Waiting for order placement.",
      icon: "fa-cart-shopping",
    },
    {
      label: "Order Confirm",
      status: "order_confirm",
      description: (currentStatus) =>
        ["order_confirm", "shipped", "delivered"].includes(currentStatus)
          ? "Seller has confirmed your order."
          : "Waiting for confirmation update.",
      icon: "fa-box",
    },
    {
      label: "Order Shipped",
      status: "shipped",
      description: (currentStatus) =>
        ["shipped", "delivered"].includes(currentStatus)
          ? "Your item has been shipped."
          : "Waiting for shipment.",
      icon: "fa-truck",
    },
    {
      label: "Order Delivered",
      status: "delivered",
      description: (currentStatus) =>
        currentStatus === "delivered"
          ? "Your item has been delivered."
          : "Delivery is still pending.",
      icon: "fa-box-open",
    },
  ];
  const getStatusIndex = () =>
    steps.findIndex((step) => step.status === currentStatus);

  const getDynamicSteps = () => {
    const updatedSteps = steps.map((step) => ({
      ...step,
      description:
        typeof step.description === "function"
          ? step.description(currentStatus)
          : step.description,
    }));

    if (currentStatus === "cancelled") {
      const cancelledStep = {
        label: "Cancelled",
        status: "cancelled",
        description: "Your order has been cancelled.",
        icon: "fa-close",
      };

      // Find the index of the step where the order was cancelled
      const lastStepIndex = steps.findIndex(
        (step) => step.status === lastCompletedStatus
      );

      // Insert the "Cancelled" step after the last completed status
      updatedSteps.splice(lastStepIndex + 1, 0, cancelledStep);
    }

    return updatedSteps;
  };
  const statuses = ["pending", "order_confirm", "shipped", "delivered"]; // Define the order of statuses

  const lastCompletedStatus = statuses
    .slice(0, statuses.indexOf(currentStatus))
    .reverse()
    .find((status) => status !== "cancelled");
  const dynamicSteps = getDynamicSteps();

  const formatDate = (dateString) => {
    return new Date(dateString)
      .toLocaleDateString("en-US", {
        month: "short",
        weekday: "short",
      })
      .split(" ")
      .reverse()
      .join(" ");
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container-fluid Order_Detail">
      <div className="row my-5">
        {newData.map((item) => {
          return (
            <div className="col-md-8 order1" key={item.id}>
              {item?.order_details.map((obj) => {
                return (
                  <div className="card" key={obj.id}>
                    <div className="productimage">
                      <img
                        key={obj.id}
                        alt={obj?.product_name}
                        src={`${ImgURL}product_image/${
                          JSON.parse(obj.product_image)[0]
                        }`}
                        loading="lazy"
                        onError={handleImageError}
                        className="card-img-top mx-auto"
                        style={{
                          height: "80px",
                          resizeMode: "cover",
                          width: "80px",
                        }}
                      />
                    </div>
                    <div className="producttext">
                      <h6>{obj?.product_name}</h6>
                      <p>
                        {rupees_icon} {item?.order_payments?.paid_amt}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="my-2 orderdetail">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Delivery Address</p>
                  <p>
                    {item.address}, {item.city}, {item.state}, {item.pincode}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Amount</p>
                  <p>
                    {rupees_icon} {item.order_total}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Payment Mode</p>
                  <p>{item.payment_mode}</p>
                </div>
                {item.coupon_code && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Coupon Applied</p>
                    <p>{item.coupon_code}</p>
                  </div>
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <p> Delivery status: </p>
                  {item.status === "cancelled" ? (
                    <p className="text-danger">{item.status}</p>
                  ) : item.status === "delivered" ? (
                    <p className="text-success">{item.status}</p>
                  ) : (
                    <p className="text-warning">{item.status}</p>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        <div className="col-md-4 order2">
          <div className="card">
            {newData.map((item) => {
              return (
                <div className="horizontal-track-order">
                  <h3>Track Your Order</h3>
                  <ul className="status-list mt-3">
                    {dynamicSteps.map((step, index) => (
                      <li
                        key={index}
                        className={`status-item ${
                          step.status === "cancelled"
                            ? "cancelled"
                            : index === getStatusIndex()
                            ? "current"
                            : index < getStatusIndex()
                            ? "completed"
                            : ""
                        }`}
                      >
                        <div className="status-icon">
                          <i className={`fa ${step.icon}`} />
                        </div>
                        <div className="status-content">
                          <h4>
                            {step.label}{" "}
                            {step.label === "Pending" ||
                            step.label === "Cancelled"
                              ? formatDate(item.updated_at)
                              : ""}
                          </h4>
                          <p>{step.description}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPlaced;
