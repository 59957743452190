const { createSlice } = require("@reduxjs/toolkit");
const ProductsSlice = createSlice({
  name: "products",
  initialState: {
    data: [],
  },
  reducers: {
    setCart: (state, action) => {
      state.data = action.payload;
    },
    addProducts(state, action) {
      const existingItemIndex = state.data.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingItemIndex !== -1) {
        state.data[existingItemIndex] = {
          ...state.data[existingItemIndex],
          qty: parseFloat(state.data[existingItemIndex].qty) + 1,
        };
      } else {
        state.data = [...state.data, { ...action.payload, qty: 1 }];
      }
      localStorage.setItem("cart", JSON.stringify(state.data));
    },
    reduceItemToCart(state, action) {
      const existingItemIndex = state.data.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingItemIndex !== -1 && state.data[existingItemIndex].qty > 1) {
        state.data[existingItemIndex] = {
          ...state.data[existingItemIndex],
          qty: parseFloat(state.data[existingItemIndex].qty) - 1,
        };
      }
      localStorage.setItem("cart", JSON.stringify(state.data));
    },
    removeItemToCart(state, action) {
      state.data = state.data.filter((item) => item.id !== action.payload);
      localStorage.setItem("cart", JSON.stringify(state.data));
    },
    refreshCart(state, action) {
      const isItemExist = state.data.some(
        (item) => item.id === action.payload.id
      );

      if (!isItemExist) {
        state.data = [...state.data, action.payload];
      }
      localStorage.setItem("cart", JSON.stringify(state.data));
    },
    clearAllData: (state) => {
      state.data = [];
      localStorage.removeItem("cart");
    },
  },
});
export const {
  setCart,
  addProducts,
  removeItemToCart,
  reduceItemToCart,
  refreshCart,
  clearAllData,
} = ProductsSlice.actions;
export default ProductsSlice.reducer;
